import { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import FormInput from './FormInput.jsx';
import Helper from './../services/Helper';
import Session from './../services/Session';
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';

import { APIRequest } from '../services/API.js';
import AsyncButton from './AsyncButton.jsx';
import { SessionContext } from '../context/SessionContext.jsx';

export default function Login(props) {
    const [redirectClear, setRedirectClear] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const buttonRef = useRef();
    const sessionContext = useContext(SessionContext);

    function redirect() {
        const login_redirect = sessionStorage.getItem('login_redirect');
        if(login_redirect) {
            sessionStorage.removeItem('login_redirect');
            window.location.href = login_redirect;
        } else {
            window.location.href = '/';
        }
    }

    function onSubmit(e) {
        if (e && e.preventDefault) e.preventDefault();

        if (inProgress) return false;
        setInProgress(true);

        Session.Login(login, password).then(response => {
            if(response.status) {
                sessionStorage.removeItem("last_session_check");
                sessionStorage.setItem('is_logged_in', '1');
                redirect();
            } else {
                buttonRef.current.reset();
                setInProgress(false);
                setErrorMessage(response.data);
            }
        });

        return false;
    }

    useEffect(() => {
        if(sessionContext.session && sessionContext.session.id) {
            sessionStorage.setItem('user_data', JSON.stringify(sessionContext.session));
            redirect();
        } else {
            setRedirectClear(true);
        }
    }, []);

    if(!redirectClear) return null;

    return (
        <div className="login">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 form-container">
                        <h1>Entra en SPOTNOW</h1>
                        <form autoComplete="off" onSubmit={onSubmit}>
                            <input type="hidden" autoComplete="off" />
                            <FormInput
                                type="email"
                                id="email"
                                label="Email"
                                onValidate={value => {
                                    if(!Helper.ValidateEmail(value)) return 'Por favor introduce una dirección de email válida.';
                                }}
                                onChange={value => {
                                    setLogin(value);
                                }}
                            />
                            <FormInput
                                type="password"
                                id="password"
                                label="Contraseña"
                                onChange={value => {
                                    setPassword(value);
                                }}
                            />

                            {
                                errorMessage ? (
                                    <div className="error-message" dangerouslySetInnerHTML={{__html: errorMessage}} />
                                ) : null
                            }
                            
                            <AsyncButton className="btn btn-brown" ref={ref => buttonRef.current = ref} onClick={onSubmit} caption="Entrar" />
                            <div className="center">¿Todavía no tienes una cuenta? <Link to="/registro">Regístrate</Link>.</div>
                            <div className="center"><Link to="/cambiar-contrasena">¿Has olvidado tu contraseña?</Link></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};