import { useState, useEffect } from "react";
import Helper from "../services/Helper";
import MaterialIcon from "./MaterialIcon";

export default function ImageUpload(props) {
	const [imageURL, setImageURL] = useState((props.initialValue && props.initialValue?.replace("/static/images/", "")) || "");
	const sizeMode = props.sizeMode || "contain";

	const empty = imageURL == "";

	return (
		<div
			className={"image-upload" + (props.className ? " " + props.className : "") + (empty ? " empty" : "")}
			style={{ height: props.height || "", backgroundSize: sizeMode, backgroundImage: "url(/static/images/" + imageURL + ")" }}
			onClick={(e) => {
				$(e.target.parentNode).find("input[type=file]").trigger("click");
			}}>
			<div className="overlay"></div>
			<img src={"/static/images/" + imageURL} />
			<div className="add-icon">
				<MaterialIcon name="library_add" />
			</div>
			<input
				type="file"
				id="file-input"
				accept="image/x-png,image/gif,image/jpeg,image/svg+xml"
				onChange={(e) => {
					if (props.onChange) {
						props.onChange(e.target.value);
					}
				}}
			/>
		</div>
	);
}
