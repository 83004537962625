import React from 'react';
import $ from 'jquery';

export default class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			custom_title: ''
		};

		this.resizeInterval = null;
	}

	componentWillUnmount() {
		$("body").removeClass("modal-open, scroll-lock");
	}

	open() {
		$("body").addClass("modal-open");
		$('body').addClass('scroll-lock');

		if (this.props.overrideBodyScrolled) {
			$("body")[0]["hadClassScrolled"] = $("body").hasClass("scrolled");
			$("body").removeClass("scrolled");
		}
		
		this.setState({
			open: true,
			custom_title: ''
		});

		if(this.props.onOpen) this.props.onOpen(this);

		if (this.props.onOpened) {
			setTimeout(() => {
				this.props.onOpened();
			});
		}

		if($(".modal-inner").outerHeight() < window.innerHeight) {
			$(".modal-content-area").css("overflow-y", "auto");
		}

		setTimeout(() => {
			$('.modal-outer').addClass('active');
		}, 1);

		if (this.props.heightMode === "expand") {
			setTimeout(() => {
				$(".modal-inner").css("height", window.innerHeight + "px");
			}, 1);

			this.resizeInterval = setInterval(() => {
				$(".modal-inner").css("height", window.innerHeight + "px");
			}, 500);
		}
	}

	close(cancelled = false) {
		$('body').removeClass('scroll-lock');
		$('body').removeClass('modal-open');

		if(this.props.overrideBodyScrolled && $("body")[0]["hadClassScrolled"]) {
			$("body").addClass("scrolled");
		}
		if(cancelled && this.props.onCancel) this.props.onCancel(this);
		if(this.props.onClose) this.props.onClose(this);
		$('.modal-outer').removeClass('active');
		clearInterval(this.resizeInterval);

		setTimeout(() => {
			this.setState({
				open: false,
				onBack: null
			});
		}, 300);
	}

	setTitle(title) {
		this.setState({ custom_title: title });
	}

	render() {
		if(!this.state.open) return null;

		return (
			<div className="modal-outer" onClick={e => {
				if(!$(e.target).hasClass('modal-outer')) return;
				this.close();
			}}>
				<div className={'modal-inner ' + (this.props.className || '')}>
					{this.state.onBack && <div className="modal-back-button" onClick={this.state.onBack}><img alt="" src="/static/icons/desplegable-preguntas.png" /></div>}
					<div className="modal-title">{this.state.custom_title || this.props.title}</div>
					<div className="modal-close-button tock-icon" onClick={() => {
						this.close(true);
					}}></div>
					<div className="modal-content-area">
						{this.props.renderContent(this)}
					</div>
				</div>
			</div>
		);
	}
}
