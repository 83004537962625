import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default class ReservaFallida extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        $('header').addClass('simple');
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    render() {
        return (
            <div className="reserva-completada">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>No se ha podido realizar la reserva</h1>
                            <h2>Por favor inténtalo de nuevo o <Link to="/contacto">contacta con nosotros</Link>.</h2>
                            <Link to="/pago" className="btn btn-brown">Volver</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
