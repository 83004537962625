import { useEffect, useRef, useState } from "react";
import Helper from "../services/Helper";
import MaterialIcon from "./MaterialIcon";

export default function VideoUpload(props) {
	const [videoURL, setVideoURL] = useState(props.initialValue || "");
	const sizeMode = props.sizeMode || "contain";
	const elementID = useRef("video-upload-" + Math.floor(Math.random() * 1000) + 1000);

	const empty = videoURL == "";

	return (
		<div
			className={"video-upload" + (props.className ? " " + props.className : "") + (empty ? " empty" : "")}
			id={elementID.current}
			style={{ height: props.height || "" }}
		>
			<video controls>
				<source src={"/static/images/" + videoURL} type="video/mp4" />
			</video>
			<div className="upload-controls">
				<a href="#" onClick={e => {
					e.preventDefault();
					$("#" + elementID.current).find("input[type=file]").trigger("click");
				}} className="add-icon"><MaterialIcon name="library_add" /></a>
			</div>
			<input
				style={{ display: "none" }}
				type="file"
				id="file-input"
				accept="video/mp4"
				onChange={(e) => {
					if (props.onChange) {
						props.onChange(e.target.value);
					}
				}}
			/>
		</div>
	);
}
