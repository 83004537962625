import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer.jsx';
import $ from 'jquery';

import Session from '../services/Session.jsx';

class RegistroActivar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activation_successful: false,
            error_message: null
        };
	}

	componentDidMount() {
		$('body').addClass('bg-offwhite');
        $("header").addClass("simple");

        try {
            const token = window.location.search.match(/token=([^&]+)/i)[1];
            Session.AttemptUserActivation(token).then(result => {
                this.setState({
                    activation_successful: result.status,
                    error_message: result.status === true ? null : result.data
                });
            });
        } catch(e) {
            this.setState({ error_message: "Parece que este enlace no es correcto." });
        }
	}

	componentWillUnmount() {
        $('body').removeClass('bg-offwhite');
        $("header").removeClass("simple");
	}

    render() {
        if(this.state.activation_successful) return (
            <div className="registro-gracias formulario-contacto contacto-gracias bg-offwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>¡Tu cuenta ha sido activada!</h1>
                            <p/>
                            <div className="subtitle"><Link className="btn btn-brown" to="/login">entrar</Link></div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
        if(!this.state.activation_successful && this.state.error_message) return (
            <div className="registro-gracias formulario-contacto contacto-gracias bg-offwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Ha ocurrido un error</h1>
                            <p/>
                            <div className="subtitle">{this.state.error_message}</div>
                            <Link to="/" className="btn btn-brown">Volver</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
        return null;
    }
}

export default RegistroActivar;