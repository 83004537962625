import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Helper from "../services/Helper";
import $ from "jquery";
import HTMLReactParser from "html-react-parser";

import Icon from "./Icon";
import Calendar from "./Calendar";
import Modal from "./Modal";
import { Content } from "./Content";
import Session from "../services/Session";
import Events from "../services/Events";
import Slider from "./Slider";
import EventInfo from "./EventInfo";
import Carousel from "./Carousel";
import PreorderTicket from "./PreorderTicket";

import { PreorderSelectionContext } from "../context/PreorderSelectionContext";

export default function PreorderSelector(props) {
	const preorderSelectionContext = useContext(PreorderSelectionContext);
	const preorderSelection = preorderSelectionContext.preorderSelection;

	const [modalData, setModalData] = useState({ dish: null, quantity: 1 });
	const [orderTicketVisible, setOrderTicketVisible] = useState(false);
	const [extraEventModalData, setExtraEventModalData] = useState({});

	const itemModalRef = useRef(null);
	const extraEventsModalRef = useRef(null);

	function recalculateWidths() {
		$(".categories h2").first().addClass("active");
		$(".injected-css").remove();
		$("body").append("<style class='injected-css'>body:not(.scrolled) header { background-color: white; }</style>");
		$(".categories-container").css("height", $(".categories-container .categories").outerHeight() + 6);
		$(".categories-container .categories").css({
			position: "absolute",
			top: 0,
			left: 0,
			transition: "left 300ms"
		});

		if (Helper.IsResponsive()) {
			const rect = document.querySelector(".categorized-scroller .categories").getBoundingClientRect();
			const containerRect = document.querySelector(".categorized-scroller").getBoundingClientRect();
			if (rect.width < containerRect.width) {
				$(".categorized-scroller .categories").css("right", 0);
			}
		} else {
			let categoriesWidth = 0;
			$(".categories h2").each(function () {
				categoriesWidth += $(this).width();
			});

			if (categoriesWidth < $(".categories").parent().width()) {
				$(".categories").css({
					display: "flex",
					justifyContent: "space-evenly",
					width: "100%"
				});
			}

			$(
				$(".categories-container").css({
					left: $(".categories-container").parent().offset().left,
					right: window.innerWidth - ($(".categories-container").parent().offset().left + $(".categories-container").parent().width()) - 15
				})
			);
		}

		// @ts-ignore
		$(window)
			.off("scroll")
			.on("scroll", function (e) {
				$(".categories h2").each(function () {
					const index = $(this).attr("data-category-index");
					if ($(".items h2[data-category-index=" + index + "]").offset().top <= $(this).offset().top + 80) {
						$(".categories h2").removeClass("active");
						$(this).addClass("active");

						onActiveCategoryChanged();
					}
				});
			});

		// @ts-ignore
		$(".categories h2")
			.off("click")
			.on("click", function (e) {
				const index = $(this).attr("data-category-index");
				const $this = $(this);

				$("body, html").animate(
					{
						scrollTop: $(".items h2[data-category-index=" + index + "]").offset().top - $("header").height() - $(".categories-container").height() - 24
					},
					"fast",
					function () {
						$(".categories h2").removeClass("active");
						$this.addClass("active");
						onActiveCategoryChanged();
					}
				);
			});

		function onActiveCategoryChanged() {
			if (!$(".categories h2.active").length) {
				return;
			}

			requestAnimationFrame(function () {
				$(".categories-container .categories").css({
					left: Math.min(0, Math.max(-$(".categories-container .categories").outerWidth() + $(".categories-container").outerWidth(), -$(".categories h2.active").position().left))
				});
			});
		}
	}

	useEffect(() => {
		$(($) => {
			window.addEventListener("resize", recalculateWidths);
			recalculateWidths();
		});

		setTimeout(() => {
			recalculateWidths();
		}, 1000);

		return () => {
			$(".injected-css").remove();
			$("header").removeClass("simple");
			window.removeEventListener("resize", recalculateWidths);
		};
	}, []);

	function renderTicketDetails() {
		const price = props.event.availability.price != -1 ? props.event.availability.price : props.event.price;
		const pax = props.event.full_booking == 1 ? 1 : props.booking.pax;

		if (props.booking.preorder_selection && props.booking.preorder_selection.length > 0) {
			const totalprice = props.booking.preorder_selection.reduce((p, v) => p + v.quantity * v.price, pax * price);
			return (
				<>
					<div className="details-line preorder-selection-item" style={{ fontSize: "0.9em", marginTop: 20 }}>
						<span className="sidebar-quantity">1</span>
						{props.event.availability.availability_description} <span style={{ marginLeft: "auto" }}>{Helper.FormatAmount((pax * price) / 100)}</span>
					</div>
					{props.booking.preorder_selection.map((item, idx) => {
						return (
							<div className="details-line preorder-selection-item" key={idx} style={{ fontSize: "0.9em" }}>
								<span className="sidebar-quantity">{item.quantity}</span>
								{item.name}
								<span style={{ marginLeft: "auto" }}>{Helper.FormatAmount((item.quantity * item.price) / 100)}</span>
							</div>
						);
					})}
					<div
						className="details-line total-price"
						style={{
							marginTop: 10,
							justifyContent: "flex-end",
							fontSize: "0.9em"
						}}>
						{(totalprice / 100).toLocaleString("es", {
							minimumFractionDigits: 2
						})}
					</div>
				</>
			);
		} else {
			return (
				<div className="details-line">
					{" "}
					<Icon name="coin" />
					{Helper.FormatAmount((pax * price) / 100)}
				</div>
			);
		}
	}

	function confirmExtraEvent() {
		const newExtraEvents = [...preorderSelection];
		const reference_id = extraEventModalData.selection.event.id;
		const existingEventIndex = newExtraEvents.findIndex((v) => v.reference_id == reference_id);

		if (existingEventIndex == -1) {
			newExtraEvents.push({
				name: extraEventModalData.negocio.name,
				event_name: extraEventModalData.selection.event.nombre,
				id: extraEventModalData.selection.availabilityRow.id,
				event_id: extraEventModalData.negocio.id,
				price: extraEventModalData.selection.availabilityRow.price,
				reference_id,
				quantity: extraEventModalData.selection.pax,
				full_booking: extraEventModalData.selection.fullBooking,
				slot: extraEventModalData.selection.availabilityRow.slot,
				selection_length: extraEventModalData.selectionLength,
				extras: extraEventModalData.selection.extras,
				address: extraEventModalData.selection.availabilityRow.address_alt ? extraEventModalData.selection.availabilityRow.address_alt : extraEventModalData.negocio.address,
				header_image_url: extraEventModalData.negocio.header_image_url,
				duration: extraEventModalData.availabilityRow?.duration || extraEventModalData.event?.duration
			});
		} else {
			newExtraEvents[existingEventIndex] = {
				...newExtraEvents[existingEventIndex],
				name: extraEventModalData.negocio.name,
				event_name: extraEventModalData.selection.event.nombre,
				id: extraEventModalData.selection.availabilityRow.id,
				event_id: extraEventModalData.negocio.id,
				price: extraEventModalData.selection.availabilityRow.price,
				reference_id,
				quantity: extraEventModalData.selection.pax,
				full_booking: extraEventModalData.selection.fullBooking,
				slot: extraEventModalData.selection.availabilityRow.slot,
				selection_length: extraEventModalData.selectionLength,
				extras: extraEventModalData.selection.extras,
				address: extraEventModalData.selection.availabilityRow.address_alt ? extraEventModalData.selection.availabilityRow.address_alt : extraEventModalData.negocio.address,
				header_image_url: extraEventModalData.negocio.header_image_url,
				duration: extraEventModalData.selection.availabilityRow.duration || extraEventModalData.event.duration
			};
		}

		preorderSelectionContext.changeSelection(newExtraEvents);
		extraEventsModalRef.current.close();
	}

	const categories = props.extras_menu ? Object.keys(props.extras_menu) : [];
	const negocios = props.negocios.filter((v, i, s) => v.events.length > 0 && s.findIndex((v2) => v2.tipo == v.tipo) === i);

	const price = props.event.availability.price != -1 ? props.event.availability.price : props.event.price != -1 ? props.event.price : props.event.negocio.price;
	let orderTotal = 0;

	if (props.params.page == "otros") {
		orderTotal = preorderSelectionContext.total + price * props.booking.pax + (props.booking.preorder_selection?.reduce((acc, item) => acc + item.quantity * item.price, 0) || 0);
	} else {
		orderTotal = preorderSelectionContext.total + price * props.booking.pax;
	}

	return (
		<React.Fragment>
			<div className="preorder-selector categorized-scroller">
				<div className="categories-container">
					<div className="categories">
						{props.params.page != "otros" &&
							categories.map((category, idx) => {
								return (
									<h2 key={idx} data-category-index={idx}>
										{category}
									</h2>
								);
							})}
						{props.params.page == "otros" &&
							negocios.map((negocio, idx) => {
								return (
									<h2 key={idx} data-category-index={"e-" + negocio.tipo.type}>
										{negocio.tipo.plural.substring(0, 1).toUpperCase() + negocio.tipo.plural.substring(1)}
									</h2>
								);
							})}
					</div>
				</div>
				{props.params.page != "otros" && (
					<div className="intro-extras">
						<Content slug="intro-extras" />
					</div>
				)}
				{props.params.page == "otros" && (
					<div className="intro-extras">
						<Content slug="intro-upselling" />
					</div>
				)}
				<div className="items">
					{props.params.page != "otros" &&
						categories.map((category, idx) => {
							return (
								<div className="item-category" key={idx}>
									<h2 data-category-index={idx}>{category}</h2>
									{props.extras_menu[category].description && <h3 className="category-description">{props.extras_menu[category].description}</h3>}
									<div className="items-grid">
										{props.extras_menu[category].items.map((dish, idx) => {
											const quantity = preorderSelection.find((d) => d.name == dish.name)?.quantity || 0;
											return (
												<div
													className="item"
													key={idx}
													onClick={(e) => {
														e.preventDefault();

														const newModalData = {
															...modalData
														};
														newModalData.dish = dish;
														newModalData.quantity = Math.max(1, quantity);
														setModalData(newModalData);

														itemModalRef.current.open();
														itemModalRef.current.setTitle(dish.name);
													}}>
													<div>
														<h3>{dish.name}</h3>
														<div className="item-description">{Helper.SummarizeText(dish.description, !Helper.IsResponsive() ? 72 : 60)}</div>
														<div className="item-price">{Helper.FormatAmount(dish.price / 100.0, true)}</div>
													</div>
													<div
														className="item-image"
														style={{
															backgroundImage: "url(" + dish.image_url + ")"
														}}>
														<img alt="" src={dish.image_url} />
													</div>
													{quantity > 0 && <div className="quantity">{quantity}</div>}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}

					{props.params.page == "otros" &&
						negocios.map((type) => {
							const negocios = props.negocios.filter((event) => event.tipo == type.tipo && event.events.length > 0);
							const tipos = [];
							negocios.forEach((evento) => {
								if (!tipos.find((t) => t.singular == evento.tipo.type)) {
									tipos.push({
										singular: evento.tipo.type,
										plural: evento.tipo.plural
									});
								}
							});

							return tipos.map((tipo, idx) => {
								return (
									<div className="item-category otros" key={idx}>
										<h2 data-category-index={"e-" + tipo.singular}>{tipo.plural.substring(0, 1).toUpperCase() + tipo.plural.substring(1)}</h2>
										<div className="items-grid">
											{negocios
												.filter((e) => e.tipo.type == tipo.singular)
												.map((negocio, idx) => {
													if (negocio.events.length == 0) {
														return null;
													}

													let minimumPrice = -1;

													negocio.events.forEach((event) => {
														event.availability.forEach((row) => {
															if (row.price == -1) {
																if (event.price == -1) {
																	if (minimumPrice == -1 || (negocio.price != -1 && negocio.price < minimumPrice)) {
																		minimumPrice = negocio.price;
																	}
																} else {
																	if (minimumPrice == -1 || (event.price != -1 && event.price < minimumPrice)) {
																		minimumPrice = event.price;
																	}
																}
															} else {
																if (minimumPrice == -1 || (row.price != -1 && row.price < minimumPrice)) {
																	minimumPrice = row.price;
																}
															}
														});
													});

													if (minimumPrice == -1) return null;

													const addedEvent = preorderSelection.find((v) => v.event_id == negocio.id);

													return (
														<div
															className="item evento-item"
															key={idx}
															onClick={(e) => {
																setExtraEventModalData({
																	negocio,
																	availability: null,
																	quantity: addedEvent?.quantity || 1,
																	slot: addedEvent?.slot,
																	date: addedEvent?.slot ? new Date(addedEvent?.slot) : null,
																	page: "availabilities",
																	selection: {
																		pax: addedEvent?.quantity || 1,
																		extras: addedEvent?.extras
																	},
																	title: negocio.name
																});
																extraEventsModalRef.current.setState({
																	onBack: null
																});
																extraEventsModalRef.current.open();
															}}>
															<div>
																<div className="evento-iconos">
																	{negocio.iconos.map((icono, idx) => {
																		return (
																			<div
																				onClick={(e) => {
																					e.preventDefault();
																					e.stopPropagation();
																					return false;
																				}}
																				style={{
																					position: "relative"
																				}}
																				key={idx}>
																				<Icon name={icono.name} pixelSize={32} />
																				<div className="icon-tooltip">{icono.description}</div>
																			</div>
																		);
																	})}
																</div>
																<h3>{negocio.name}</h3>
																<h4>{negocio.city}</h4>
																<div className="item-price">Desde {Helper.FormatAmount(minimumPrice / 100.0, true)}</div>
															</div>
															<div
																className="item-image"
																style={{
																	backgroundImage: "url(/static/images/eventos/" + negocio.image_url + ")"
																}}>
																<img alt="" src={"/static/images/eventos/" + negocio.image_url} />
															</div>
															{addedEvent?.quantity && <div className="quantity">{Helper.FormatAmount(((addedEvent.full_booking ? 1 : addedEvent.quantity) * parseInt(addedEvent.price) + (addedEvent.extras?.reduce((acc, extra) => acc + parseInt(extra.price) * extra.qty, 0) || 0)) / 100)}</div>}
														</div>
													);
												})}
										</div>
									</div>
								);
							});
						})}
				</div>
				<div className={"boton-confirmar-container" + (orderTicketVisible ? " active" : "")}>
					<div className="mobile-only order-ticket-toggle">
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								if (orderTicketVisible) {
									$("body").removeClass("scroll-lock");
								} else {
									$("body").addClass("scroll-lock");
								}
								setOrderTicketVisible(!orderTicketVisible);
							}}>
							{orderTicketVisible ? "Ocultar detalle de la reserva" : "Ver detalle de la reserva"} <img src="/static/icons/desplegable-preguntas.png" />
						</a>
					</div>
					{orderTicketVisible && (
						<div className="mobile-only order-ticket-details">
							<div className="event-information">
								<div>
									<div
										className="image"
										style={{
											backgroundImage: "url(/static/images/eventos/" + (props.event.image_url_alt || props.event.image_url) + ")"
										}}></div>
									<div className="name">{props.event.name}</div>
									<div className="address-name">{props.event.address_name_alt ? props.event.address_name_alt : props.event.address_name}</div>
								</div>
							</div>
							<PreorderTicket event={props.event} booking={props.booking} page={props.params.page} />
						</div>
					)}
					<a
						className="btn btn-brown boton-confirmar"
						onClick={(e) => {
							props.onConfirm();
						}}>
						{orderTotal > 0 && (
							<span>
								Confirmar total <span style={{ marginLeft: 25 }}>{Helper.FormatAmount(orderTotal / 100)}</span>
							</span>
						)}
						{orderTotal == 0 && (
							<span>
								Continuar sin extras <span className="button-total"></span>
							</span>
						)}
					</a>
				</div>
			</div>

			<Modal
				ref={(ref) => (itemModalRef.current = ref)}
				renderContent={() => {
					const itemWasAdded = preorderSelection.find((d) => d.name == modalData.dish.name) !== undefined;
					return (
						<div className="item">
							<div>
								<div
									className="item-image"
									style={{
										backgroundImage: "url(" + modalData.dish.image_url + ")"
									}}>
									<img src={modalData.dish.image_url} alt="" />
								</div>
								<div className="item-description">{modalData.dish.description}</div>
								<div className="item-quantity-selector">
									<div className="qty-controls">
										<div
											className="qty-button"
											onClick={(e) => {
												e.preventDefault();

												const newModalData = {
													...modalData
												};
												newModalData.quantity = Math.max(itemWasAdded ? 0 : 1, modalData.quantity - 1);
												setModalData(newModalData);
											}}>
											<i className="tock-icon icon_minus"></i>
										</div>
										<div className="qty" data-price={modalData.dish.price} data-dish-name={modalData.dish.name}>
											{modalData.quantity}
										</div>
										<div
											className="qty-button"
											onClick={(e) => {
												e.preventDefault();

												const newModalData = {
													...modalData
												};
												newModalData.quantity = Math.max(0, modalData.quantity + 1);
												setModalData(newModalData);
											}}>
											<i className="tock-icon icon_plus"></i>
										</div>
									</div>
									<a
										className="btn btn-brown confirm-item"
										onClick={(e) => {
											e.preventDefault();
											const newPreorderSelection = [...preorderSelection];

											if (itemWasAdded && modalData.quantity == 0) {
												newPreorderSelection.splice(
													newPreorderSelection.findIndex((d) => d.name == modalData.dish.name),
													1
												);
											} else {
												if (itemWasAdded) {
													newPreorderSelection[newPreorderSelection.findIndex((d) => d.name == modalData.dish.name)].quantity = modalData.quantity;
												} else {
													newPreorderSelection.push({
														name: modalData.dish.name,
														quantity: modalData.quantity,
														price: modalData.dish.price
													});
												}
											}

											preorderSelectionContext.changeSelection(newPreorderSelection);
											itemModalRef.current.close();
										}}>
										{!itemWasAdded && (
											<div>
												Añadir <span className="item-price">{Helper.FormatAmount((modalData.quantity * modalData.dish.price) / 100.0, true)}</span>
											</div>
										)}
										{itemWasAdded && <div>Actualizar {modalData.quantity > 0 && <span className="item-price">{Helper.FormatAmount((modalData.quantity * modalData.dish.price) / 100.0, true)}</span>}</div>}
									</a>
								</div>
							</div>
						</div>
					);
				}}
			/>
			<Modal
				className={"modal-upselling page-" + (extraEventModalData.page || "unset")}
				ref={(ref) => (extraEventsModalRef.current = ref)}
				title={extraEventModalData?.title}
				onOpened={() => {
					$(".preorder .modal-venue .carousel-container").css({
						height: $(".preorder .modal-venue .carousel-container .carousel-inner").height() + (window.innerWidth <= 992 ? 0 : 25)
					});
				}}
				renderContent={() => {
					if (!extraEventModalData) return null;

					if (extraEventModalData.page == "availabilities") {
						const negocio = extraEventModalData.negocio;
						const header_image_url = negocio.header_image_url;

						return (
							<div className="modal-venue">
								{negocio.slider && (
									<Slider
										style={{
											opacity: negocio.slider.length ? 1 : 0,
											height: 350,
											minHeight: 350
										}}
										height={350}
										className="slider-ficha-evento slider-upselling"
										effect="fade"
										delay={5000}
										slides={negocio.slider}
									/>
								)}
								{!negocio.slider && (
									<div
										className="header-image"
										style={{
											backgroundImage: "url(/static/images/eventos/" + header_image_url + ")"
										}}
									/>
								)}
								<div className="inner">
									<h1>{negocio.name}</h1>
									<div className="event-address">
										<div className="address-name">{negocio.address_name}</div>
										<div className="address">{negocio.address}</div>
									</div>
									<div className="tags"></div>

									<div className="event-description">
										<EventInfo negocio={negocio} />
									</div>

									<div className="event-description iconos">
										<div className="event-text event-icons">
											{negocio.iconos.map((icono, idx) => {
												return (
													<div className="icon-container" key={idx}>
														<Icon name={icono.name} size={3} />
														<div className="icon-tooltip">{icono.description}</div>
													</div>
												);
											})}
										</div>
									</div>

									<div className="items-container">
										<div
											className="items"
											style={{
												width: Helper.IsResponsive() ? "calc(" + negocio.events.length * 100 + "vw - " + negocio.events.length * 75 + "px)" : "auto"
											}}>
											<Carousel
												className="items"
												elements={negocio.events.filter((event) => event.availability.reduce((pax, row) => pax + row.pax, 0) > 0)}
												items={2}
												renderElement={(event, idx) => {
													const reference_id = event.id;
													const addedEvent = preorderSelection.find((av) => av.reference_id == reference_id);
													const image_url = event.image_url || negocio.image_url;

													let minimumPrice = -1;
													event.availability.forEach((availability) => {
														if (availability.price != -1) {
															if (minimumPrice == -1 || availability.price < minimumPrice) {
																minimumPrice = availability.price;
															}
														} else {
															if (event.price != -1) {
																if (minimumPrice == -1 || event.price < minimumPrice) {
																	minimumPrice = event.price;
																}
															} else {
																if (negocio.price != -1 && (negocio.price < minimumPrice || minimumPrice == -1)) {
																	minimumPrice = negocio.price;
																}
															}
														}
													});

													return (
														<div
															className="item"
															style={{
																width: Helper.IsResponsive() ? "calc(100vw - 91px)" : "auto"
															}}
															key={idx}
															onClick={(e) => {
																e.preventDefault();
																extraEventsModalRef.current.setState({
																	onBack: () => {
																		extraEventsModalRef.current.setState({
																			onBack: null
																		});
																		const newExtraEventModalData = {
																			...extraEventModalData
																		};
																		newExtraEventModalData.page = "availabilities";
																		setExtraEventModalData(newExtraEventModalData);
																	}
																});
																const newExtraEventModalData = {
																	negocio,
																	event,
																	quantity: addedEvent?.quantity || parseInt(Session.GetBookingData("pax")),
																	selectionLength: 1,
																	calendar: event.calendar,
																	slot: addedEvent?.slot,
																	date: addedEvent?.slot ? new Date(addedEvent?.slot) : null,
																	page: "calendar",
																	selection: {
																		...addedEvent?.selection,
																		pax: addedEvent?.quantity || 1,
																		extras: addedEvent?.extras,
																		event
																	},
																	title: (
																		<div
																			style={{
																				textAlign: "center",
																				flex: 1,
																				maxWidth: "calc(100% - 100px)"
																			}}>
																			<span
																				style={{
																					display: "inline",
																					whiteSpace: "pre",
																					textAlign: "center"
																				}}>
																				{negocio.name}
																			</span>{" "}
																			<span
																				style={{
																					display: "inline",
																					whiteSpace: "pre",
																					textAlign: "center"
																				}}>
																				({event.nombre})
																			</span>
																		</div>
																	)
																};
																setExtraEventModalData(newExtraEventModalData);
															}}>
															<div className="image-container">
																<div
																	className="item-image"
																	style={{
																		backgroundImage: "url(/static/images/eventos/" + image_url + ")"
																	}}>
																	<img alt="" src={"/static/images/eventos/" + image_url} />
																</div>
																<h3>{event.nombre}</h3>
																<div className="bottom-details">
																	<div className="item-price details-tag">Desde {Helper.FormatAmount(minimumPrice / 100.0, true)}</div>
																	{event.duration > 0 && <div className="item-time details-tag">{Helper.FormatDuration(event.duration, true)}</div>}
																</div>
															</div>
															<div className="description-text">
																<h3 className="event-info-name">{event.nombre}</h3>
																{HTMLReactParser(event.description)}
															</div>
															{addedEvent?.quantity && <div className="quantity">{Helper.FormatAmount((addedEvent.quantity * parseInt(addedEvent.price) + (addedEvent.extras?.reduce((acc, extra) => acc + parseInt(extra.price) * extra.qty, 0) || 0)) / 100)}</div>}
														</div>
													);
												}}
											/>
										</div>
									</div>

									<div className="mobile-only event-description">
										<div className="event-text">{HTMLReactParser(negocio.description)}</div>
									</div>

									<div className="desktop-only event-description">
										<div className="event-text">{HTMLReactParser(negocio.description)}</div>
									</div>

									{negocio.iconos_detalle && (
										<div className="event-description facts">
											{JSON.parse(negocio.iconos_detalle).map((detalle, idx) => {
												return (
													<div key={idx}>
														<Icon name={detalle.icono} size={3} />
														<div>
															<div>
																<b>{detalle.titulo}</b>
															</div>
															<div>
																{detalle.lista.map((el, idx) => (
																	<div key={idx}>{el}</div>
																))}
															</div>
														</div>
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						);
					}

					if (extraEventModalData.page == "calendar") {
						return (
							<div className="modal-calendario">
								<div className="top-bar">
									<div className="pax-selector">
										<div className="pax">{extraEventModalData.quantity + " persona" + (extraEventModalData.quantity == 1 ? "" : "s")}</div>
										<div
											className="pax-button"
											onClick={(e) => {
												e.preventDefault();
												const newData = {
													...extraEventModalData
												};
												newData.quantity = Math.max(1, extraEventModalData.quantity - 1);
												setExtraEventModalData(newData);
											}}>
											<Icon name="minus" size={1.5} />
										</div>
										<div
											className="pax-button"
											onClick={(e) => {
												e.preventDefault();
												const newData = {
													...extraEventModalData
												};
												newData.quantity = Math.max(1, extraEventModalData.quantity + 1);
												setExtraEventModalData(newData);
											}}>
											<Icon name="plus" size={1.5} />
										</div>
									</div>
									{extraEventModalData.negocio.tipo == "alojamiento" && (
										<div className="selection-length-selector">
											<div className="selection-length">{extraEventModalData.selectionLength + " noche" + (extraEventModalData.selectionLength == 1 ? "" : "s")}</div>
											<div
												className="selection-length-button"
												style={{
													opacity: extraEventModalData.date ? 1.0 : 0.5
												}}
												onClick={(e) => {
													if (!extraEventModalData.date) {
														return;
													}

													e.preventDefault();
													const newData = {
														...extraEventModalData
													};
													newData.selectionLength = Math.max(1, extraEventModalData.selectionLength - 1);
													setExtraEventModalData(newData);
												}}>
												<Icon name="minus" size={1.5} />
											</div>
											<div
												className="selection-length-button"
												style={{
													opacity: extraEventModalData.date ? 1.0 : 0.5
												}}
												onClick={(e) => {
													if (!extraEventModalData.date) {
														return;
													}

													e.preventDefault();
													const newData = {
														...extraEventModalData
													};
													let newSelectionLength = extraEventModalData.selectionLength;
													for (let i = 0; i < extraEventModalData.selectionLength + 1; i++) {
														const isoDate = Helper.GetISODate(new Date(extraEventModalData.date.getFullYear(), extraEventModalData.date.getMonth(), extraEventModalData.date.getDate() + i));
														if (!extraEventModalData.calendar[isoDate]) break;
														newSelectionLength = i + 1;
													}
													newData.selectionLength = newSelectionLength;

													setExtraEventModalData(newData);
												}}>
												<Icon name="plus" size={1.5} />
											</div>
										</div>
									)}
									<div className="date-display">{extraEventModalData.date ? Helper.FormatDate(extraEventModalData.date, Helper.DATE_VERBOSE_SHORT) : "Selecciona una fecha"}</div>
								</div>
								<Calendar
									className="calendario-evento"
									selectionLength={extraEventModalData.selectionLength}
									availabilityCalendar={extraEventModalData.calendar}
									defaultValue={extraEventModalData.date}
									onSelectionChanged={(dates) => {
										if (dates && dates.length) {
											const newData = {
												...extraEventModalData
											};
											newData.slots = extraEventModalData.calendar[Helper.GetISODate(dates[0])] || {};
											newData.selectionLength = 1;
											newData.selectedDates = dates;
											newData.date = dates[0];
											setExtraEventModalData(newData);
										}
									}}
								/>
								{extraEventModalData.date && extraEventModalData.slots && (
									<div className="slots">
										{Object.keys(extraEventModalData.slots).map((slot, idx) => {
											const availablePax = extraEventModalData.slots[slot];
											const disabled = extraEventModalData.quantity > availablePax;
											const availabilityRow = extraEventModalData.event.availability.find((row) => row.slot.substring(0, 16) == Helper.GetISODate(extraEventModalData.date) + " " + slot);

											let slotPrice = 0;

											if (extraEventModalData.negocio.disponibilidad_multiple || extraEventModalData.event.disponibilidad_multiple) {
												extraEventModalData.selectedDates.forEach((date) => {
													const otherAvailabilityRow = extraEventModalData.event.availability.rows.find((row) => row.slot.substring(0, 16) == Helper.GetISODate(date) + " " + slot);
													slotPrice += parseFloat(otherAvailabilityRow.price != -1 ? otherAvailabilityRow.price : extraEventModalData.event.price != -1 ? extraEventModalData.event.price : extraEventModalData.negocio.price);
												});
											} else {
												slotPrice = parseFloat(availabilityRow.price != -1 ? availabilityRow.price : extraEventModalData.event.price != -1 ? extraEventModalData.event.price : extraEventModalData.negocio.price);
											}

											availabilityRow.price = slotPrice;

											return (
												<div
													className={"slot-row" + (disabled ? " disabled" : "") + (extraEventModalData.slot?.split(" ")[1].substring(0, 5) == slot ? " selected" : "")}
													key={idx}
													onClick={(e) => {
														if (disabled) return;

														extraEventsModalRef.current.setState({
															onBack: () => {
																extraEventsModalRef.current.setState({
																	onBack: () => {
																		extraEventsModalRef.current.setState({
																			onBack: null
																		});
																		const newData = {
																			...extraEventModalData
																		};
																		newData.page = "availabilities";
																		setExtraEventModalData(newData);
																	}
																});
																const newData = {
																	...extraEventModalData
																};
																newData.page = "calendar";
																setExtraEventModalData(newData);
															}
														});

														setExtraEventModalData({
															...extraEventModalData,
															slot: availabilityRow.slot,
															selection: {
																...extraEventModalData.selection,
																fullBooking: extraEventModalData.event.full_booking == 1,
																availabilityRow: availabilityRow,
																pax: extraEventModalData.quantity,
																extras: extraEventModalData.slot?.split(" ")[1].substring(0, 5) == slot ? extraEventModalData.selection.extras : []
															},
															page: "extras"
														});
													}}>
													<div className="slot-time">{slot}</div>
													<div className="available-pax">{availablePax} plazas</div>
													{extraEventModalData.event.full_booking == 1 && <div className="slot-amount">{Helper.FormatAmount(slotPrice / 100.0)}</div>}
													{extraEventModalData.event.full_booking != 1 && (
														<div className="slot-amount">
															{extraEventModalData.quantity} &times; {Helper.FormatAmount(slotPrice / 100.0)}
														</div>
													)}
												</div>
											);
										})}
									</div>
								)}
							</div>
						);
					}

					const getUpsellingExtrasTotal = () => {
						return extraEventModalData.selection.extras?.reduce((acc, item) => acc + item.price * item.qty, 0);
					};

					if (extraEventModalData.page == "extras") {
						if (!(extraEventModalData.selection.availabilityRow.extras_menu || extraEventModalData.event.extras_menu || extraEventModalData.negocio.preorder_menu)) {
							setTimeout(() => {
								confirmExtraEvent();
							});
							return null;
						}

						const preorderMenu = JSON.parse(extraEventModalData.selection.availabilityRow.extras_menu || extraEventModalData.event.extras_menu || extraEventModalData.negocio.preorder_menu);

						return (
							<div className="modal-extras extras-upselling">
								{Object.keys(preorderMenu).map((catName, idx) => {
									const cat = preorderMenu[catName];
									return (
										<div className="category" key={idx}>
											<h2>{catName}</h2>
											<div className="category-description">{cat.description}</div>
											<div className="category-items items">
												{cat.items.map((item, idx) => {
													const extraObject = extraEventModalData.selection.extras.find((v) => v.name == item.name);
													const extraObjectIndex = extraEventModalData.selection.extras.findIndex((v) => v.name == item.name);
													const qty = extraObject?.qty || 0;
													const selectedPrice = item.price * qty;
													return (
														<div className="item" key={idx}>
															<div
																className="item-image"
																style={{
																	backgroundImage: "url(" + item.image_url + ")"
																}}></div>
															<div>
																<h3>{item.name}</h3>
																<div className="item-description">{item.description}</div>
																<div className="lower-row">
																	<div className="item-qty-selector">
																		<Icon
																			name="minus"
																			onClick={() => {
																				const newExtras = extraEventModalData.selection.extras;
																				if (extraObject) {
																					newExtras[extraObjectIndex].qty = Math.max(0, qty - 1);
																				} else {
																					item.qty = Math.max(0, qty - 1);
																					newExtras.push(item);
																				}

																				const newData = {
																					...extraEventModalData
																				};
																				newData.selection = {
																					...extraEventModalData.selection,
																					extras: newExtras
																				};
																				setExtraEventModalData(newData);
																			}}
																		/>
																		<Icon
																			name="plus"
																			onClick={() => {
																				const newExtras = extraEventModalData.selection.extras;
																				if (extraObject) {
																					newExtras[extraObjectIndex].qty = qty + 1;
																				} else {
																					item.qty = qty + 1;
																					newExtras.push(item);
																				}

																				const newData = {
																					...extraEventModalData
																				};
																				newData.selection = {
																					...extraEventModalData.selection,
																					extras: newExtras
																				};
																				setExtraEventModalData(newData);
																			}}
																		/>
																		<div className={"item-price" + (selectedPrice > 0 ? "" : " muted")}>
																			{qty > 0 && <div className="qty">{qty} &times;&nbsp;</div>}
																			{Helper.FormatAmount((selectedPrice > 0 ? selectedPrice : item.price) / 100)}
																		</div>
																	</div>
																	<div></div>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
								<div className="boton-confirmar-container">
									<button
										className="btn btn-brown boton-confirmar"
										onClick={(e) => {
											confirmExtraEvent();
										}}>
										<span>CONFIRMAR</span>
										{getUpsellingExtrasTotal() != 0 && <span className="button-total">+{Helper.FormatAmount(getUpsellingExtrasTotal() / 100)}</span>}
									</button>
								</div>
							</div>
						);
					}

					return null;
				}}
			/>
		</React.Fragment>
	);
}
