import { useState, useEffect, useCallback, useContext } from "react";

import AdminService from "../services/Admin";
import ContentHelper from "../services/ContentHelper";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import Field from "./Field";
import { DialogContext } from "../context/DialogContext";
import LoadingIndicator from "./LoadingIndicator";

export default function AdminContacto(props) {
	const [contactoEmpresasTitulo, setContactoEmpresasTitulo] = useState(null);
	const [contactoEmpresasSubtitulo, setContactoEmpresasSubtitulo] = useState(null);
	const [contactoTitulo, setContactoTitulo] = useState(null);
	const [contactoSubtitulo, setContactoSubtitulo] = useState(null);
	const [contactEmail, setContactEmail] = useState(null);
	const [contactEmpresasEmail, setContactEmpresasEmail] = useState(null);
	const [ loadingInProgress, setLoadingInProgress ] = useState(false);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		setLoadingInProgress(true);
		Promise.all([ContentHelper.GetString("contacto-empresas-titulo"), ContentHelper.GetString("contacto-empresas-subtitulo"), ContentHelper.GetString("contacto-titulo"), ContentHelper.GetString("contacto-subtitulo"), AdminService.GetSettings("CONTACT_EMAIL"), AdminService.GetSettings("CONTACT_ALT_EMAIL")]).then(([tituloEmpresas, subtituloEmpresas, titulo, subtitulo, email, altEmail]) => {
			setContactoEmpresasTitulo(tituloEmpresas);
			setContactoEmpresasSubtitulo(subtituloEmpresas);
			setContactoTitulo(titulo);
			setContactoSubtitulo(subtitulo);
			setContactEmail(email);
			setContactEmpresasEmail(altEmail);
			setLoadingInProgress(false);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	if (loadingInProgress) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-contacto">
			<AdminBar onSave={notifySaved => {
				dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
					if (!confirmed) {
						notifySaved(false);
						return;
					}

					Promise.all([AdminService.SaveContent("contacto-empresas-titulo", contactoEmpresasTitulo), AdminService.SaveContent("contacto-empresas-subtitulo", contactoEmpresasSubtitulo), AdminService.SaveContent("contacto-titulo", contactoTitulo), AdminService.SaveContent("contacto-subtitulo", contactoSubtitulo), AdminService.SetSettings("CONTACT_EMAIL", contactEmail), AdminService.SetSettings("CONTACT_ALT_EMAIL", contactEmpresasEmail)]).then(() => {
						notifySaved(true);
					});
				});
			}} />
			<Bloque className="columnas">
				<div className="columna">
					<h2>Formulario de contacto general</h2>
					{contactoTitulo !== null && <Field placeholder="Título" type="string" onChange={(value) => setContactoTitulo(value)} defaultValue={contactoTitulo} />}
					{contactoSubtitulo !== null && <Field placeholder="Subtítulo" type="text" onChange={(value) => setContactoSubtitulo(value)} defaultValue={contactoSubtitulo} />}
					{contactEmail !== null && <Field placeholder="Email de contacto" type="string" onChange={(value) => setContactEmail(value)} defaultValue={contactEmail} />}
				</div>

				<div className="columna">
					<h2>Formulario para empresas</h2>
					{contactoTitulo !== null && <Field placeholder="Título" type="string" onChange={(value) => setContactoEmpresasTitulo(value)} defaultValue={contactoEmpresasTitulo} />}
					{contactoSubtitulo !== null && <Field placeholder="Subtítulo" type="text" onChange={(value) => setContactoEmpresasSubtitulo(value)} defaultValue={contactoEmpresasSubtitulo} />}
					{contactEmpresasEmail !== null && <Field placeholder="Email de contacto" type="string" onChange={(value) => setContactEmpresasEmail(value)} defaultValue={contactEmpresasEmail} />}
				</div>
			</Bloque>
		</div>
	);
}
