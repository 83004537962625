import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormInput from './FormInput.jsx';
import Session from './../services/Session';
import $ from 'jquery';
import { Content } from './Content.jsx';

class Registro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error_message: null
        };
    }

    render() {
        return (
            <div className="registro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 form-container">
                            <h1>Regístrate</h1>
                            <Content slug="registro-subtitulo-registrate" />
                            <form autoComplete="off" style={{
                                display: 'grid',
                                gridTemplateColumns: '2fr 2fr',
                                gap: 10
                            }}
                            onSubmit={this.onSubmit.bind(this)}>
                                <input type="hidden" autoComplete="off" />
                                <FormInput required
                                    type="email"
                                    id="email"
                                    label="Email"
                                    onValidate={value => {
                                        // eslint-disable-next-line no-control-regex
                                        if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
                                    }}
                                    onChange={value => {
                                        this.setState({ login: value })
                                    }}
                                />
                                <FormInput required
                                    type="email"
                                    id="email-repetir"
                                    label="Repetir email"
                                    onValidate={value => {
                                        // eslint-disable-next-line no-control-regex
                                        if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
                                        if(this.state.login !== value) return 'Los emails no coinciden.';
                                    }}
                                />
                                <FormInput required
                                    type="password"
                                    id="password"
                                    label="Contraseña"
                                    onValidate={value => {
                                        if(value.length < 8) return 'La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.';
                                    }}
                                    onChange={value => {
                                        this.setState({ password: value })
                                    }}
                                />
                                <FormInput required
                                    type="password"
                                    id="password2"
                                    label="Repetir contraseña"
                                    onValidate={value => {
                                        if(value.length && this.state.password !== value) return 'Las contraseñas no coinciden.';
                                    }}
                                />
                                <FormInput required type="text" id="nombre" label="Nombre"
                                onChange={value => {
                                    this.setState({ name: value })
                                }} />
                                <FormInput required type="text" id="apellidos" label="Apellidos"
                                onChange={value => {
                                    this.setState({ surname: value })
                                }} />
                                <FormInput type="phone" id="telefono" label="Teléfono (opcional)"
                                onChange={value => {
                                    this.setState({ phone: value || '' })
                                }} />
                                <FormInput type="text" id="codigo-postal" label="Código postal (opcional)"
                                    onValidate={val => {
                                        if(!val.trim()) return;
                                        if(('' + parseInt(val)).length !== 5) return 'Introduce un código postal válido.';
                                    }}
                                    onChange={value => {
                                        this.setState({ postal_code: value || '' })
                                    }}
                                />

                                { this.state.error_message && <div className="error-message">{this.state.error_message}</div> }

                                <button style={{ gridColumn: 'span 2' }} className="btn btn-brown" onClick={this.onSubmit.bind(this)}>Registrarse</button>
                                <div className="center" style={{ gridColumn: 'span 2' }}>¿Ya tienes una cuenta? <Link to="/login">Identifícate</Link>.</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onSubmit(e) {
        if(e) e.preventDefault();

        if($('.form-input.not-validated').length === 0) {
            this.setState({
                error_message: null
            });
            Session.Register(this.state.login, this.state.password, this.state.name, this.state.surname, this.state.phone, this.state.postal_code).then(response => {
                if(response.status) {
                    sessionStorage.setItem('is_logged_in', '1');
                    if(!sessionStorage.getItem('login_redirect')) {
                        sessionStorage.setItem("login_redirect", "/cuenta-creada");
                    }
                    window.location.href = '/login';
                } else {
                    this.setState({
                        error_message: response.data.error
                    });
                }
            });
        }

        return false;
    }
}

export default Registro;
