import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import Calendar from "./Calendar";
import MaterialIcon from "./MaterialIcon";
import Bloque from "./AdminBloque";
import TabMenu from "./TabMenu";
import Modal from "./Modal";
import LoadingIndicator from "./LoadingIndicator";
import Session from "../services/Session";
import Helper from "../services/Helper";
import Orders from "../services/Orders";
import Events from "../services/Events";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";
import FormInput from './FormInput.jsx';
import InputSelect from './InputSelect.jsx';
import TimeInput from "./TimeInput";
import Field from "./Field";
import { SessionContext } from "../context/SessionContext";

export default function Partners() {
    const navigate = useNavigate();
    const [ordersCalendar, setOrdersCalendar] = useState({});
    const [availabilityCalendar, setAvailabilityCalendar] = useState({});
    const [selectedDates, setSelectedDates] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountEvents, setAccountEvents] = useState([]);
    const [selectedAccountEventIndex, setSelectedAccountEventIndex] = useState(-1);
    const [accountListOpen, setAccountListOpen] = useState(false);
    const [openOrderDetailsIndex, setOpenOrderDetailsIndex] = useState(-1);
    const [orderDetailsEdittingQuantity, setOrderDetailsEdittingQuantity] = useState("");
	const [eventSelectionLocked, setEventSelectionLocked] = useState();
    const [availabilityDescriptions, setAvailabilityDescriptions] = useState();
    const sessionContext = useContext(SessionContext);

    const changeDate = useRef();
    const accountEventsFetchInterval = useRef();
    const addExtrasModalRef = useRef();
    const addOrderModalRef = useRef();
    const editAvailabilityModalRef = useRef();
    const showDialog = useRef();

	useEffect(() => {
		if (accountEvents.length == 0 || eventSelectionLocked) return;
		const selectedIndex = accountEvents.findIndex(ev => parseInt(ev.order_count) > 0);
		setSelectedAccountEventIndex(selectedIndex);
	}, [accountEvents]);

    const resetSelections = useCallback(() => {
        setSelectedDates([]);
        changeDate.current(null);
        setOpenOrderDetailsIndex(-1);
    }, []);

    async function getAccountEvents() {
        const events = await Session.GetAccountEvents();
        setAccountEvents(events.sort((a, b) => a.name > b.name ? 1 : -1));
    }

    function filterAvailabilityCalendar(dates) {
        let availability = [];
		let availabilityGroups = [];

        dates.forEach(date => {
            availability = availability.concat(availabilityCalendar[Helper.GetISODate(date)]);
        });

	availability.filter(v => v).forEach(row => {
	    let assigned = false;

	    for (let i=0; i<availabilityGroups.length; i++) {
			let assignable = true;
			const keys = Object.keys(availabilityGroups[i][0]);

			// Todos los datos deben coincidir, excepto la fecha y el ID.
			for (let j=0; j<keys.length; j++) {
				const key = keys[j];
				if (
					(key != "event" && key != "id" && key != "slot" && availabilityGroups[i][0][key] != row[key]) ||
					(key == "event" && availabilityGroups[i][0].event.id != row.event.id))
				{
					assignable = false;
					break;
				}
			}

			// Queremos que tengan la misma hora.
			if (assignable && availabilityGroups[i][0].slot.split(" ")[1] == row.slot.split(" ")[1]) {
				availabilityGroups[i].push(row);
				assigned = true;
			}
	    }

	    if (!assigned) {
			availabilityGroups.push([row]);
	    }
	});

        return availabilityGroups;
    }

    function renderAccountList() {
        return <>
            {
                accountEvents && accountEvents[selectedAccountEventIndex] && <div className={"accounts-list" + (accountListOpen ? " active" : "")} onClick={e => {
                    setAccountListOpen(!accountListOpen);
                }}>{accountEvents[selectedAccountEventIndex].name} <span><img src="/static/icons/desplegable-preguntas.png" /></span>
                </div>
            }
            {
                accountListOpen && <div className="accounts-list-options">
                    {accountEvents.map((event, idx) => {
                        if (accountEvents[selectedAccountEventIndex].name == event.name) {
                            return null;
                        }

                        return <div
                            key={idx}
                            className={"accounts-list-item" + (event.future_order_count > 0 ? " has-orders" : "")}
                            onClick={e => {
								setEventSelectionLocked(true);
                                setSelectedAccountEventIndex(idx);
                                setAccountListOpen(false);
                                resetSelections();
                            }}>{event.name}</div>;
                    })}
                </div>
            }
        </>;
    }

    const updateCalendarData = useCallback(() => {
        if (selectedAccountEventIndex < 0 || accountEvents.length <= selectedAccountEventIndex) {
            return;
        }

        setLoading(true);

        Promise.all([
            Session.GetPartnerCalendar(accountEvents[selectedAccountEventIndex].id),
            Session.GetPartnerAvailabilityCalendar(accountEvents[selectedAccountEventIndex].id)
        ]).then(([calendar, availabilityCalendar]) => {
            setLoading(false);
            setOrdersCalendar(calendar);
            setAvailabilityCalendar(availabilityCalendar);
        });
    }, [selectedAccountEventIndex, accountEvents]);

    const fetchAvailabilityDescriptions = useCallback(() => {
        Events.GetEventNames().then(descriptions => {
            setAvailabilityDescriptions(descriptions);
        });
    }, []);

    useEffect(() => {
        updateCalendarData();
    }, [updateCalendarData]);

    useEffect(() => {
        if (!sessionContext.session) {
            navigate("/");
            return;
        }
        
        accountEventsFetchInterval.current = setInterval(() => {
            getAccountEvents();
        }, 60000);

        getAccountEvents();

        setSelectedAccountEventIndex(0);

        return () => {
            clearInterval(accountEventsFetchInterval.current);
        }
    }, [navigate]);

    useEffect(() => {
        fetchAvailabilityDescriptions();
    }, [fetchAvailabilityDescriptions]);

    if (!sessionContext.session) {
        return null;
    }

    return <div className="partners container">
        {loading && <LoadingIndicator />}
        <div className="row">
            <div className="col" style={{
                position: "relative"
            }}>
                <h1>Panel partners</h1>
            </div>
        </div>
        <div className="row">
            <div className="col">
                {Helper.IsResponsive() && <div className="account-list-container">{renderAccountList()}</div>}
                <TabMenu
                    customElementsLeft={!Helper.IsResponsive() && renderAccountList}
                    onChange={tab => {
                        resetSelections();
                    }}
                    tabs={[
                    {
                        caption: "Reservas",
                            render: () => {
                                let previousAvailabilityDescription = "";

                                return <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Calendar
                                                months={Helper.IsResponsive() ? 1 : 3}
                                                changeDateRef={ref => changeDate.current = ref}
                                                allowPast={true}
                                                availabilityCalendar={ordersCalendar}
                                                onSelectionChanged={dates => {
                                                    const date = dates[0];
                                                    if (date) {
                                                        setSelectedDates(dates);
                                                        setOpenOrderDetailsIndex(-1);
                                                        setOrders(ordersCalendar[Helper.GetISODate(dates[0])]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        selectedDates.length > 0 && <div className="row">
                                            <div className="col">
                                                <span className="btn add-order-button" onClick={e => {
                                                    addOrderModalRef.current.setState({
                                                        selectedDate: selectedDates[0],
                                                        event: accountEvents[selectedAccountEventIndex]
                                                    });
                                                    setTimeout(() => addOrderModalRef.current.open(), 1);
                                                }}>Crear reserva</span>
                                                <h3>{Helper.UCFirst(Helper.FormatDate(selectedDates[0], Helper.DATE_VERBOSE_LONGEST))}</h3>
                                            </div>
                                        </div>
                                    }
                                    {
                                        selectedDates.length > 0 && orders.map((order, idx) => {
                                            const has_extras = order.preorder_selection && order.preorder_selection.length > 0;
                                            let description = "";
                                            if (order.details.availability_description != previousAvailabilityDescription) {
                                                description = order.details.availability_description;
                                                previousAvailabilityDescription = order.details.availability_description;
                                            }
                                            return <div className="col" key={idx}>
                                                {description != "" && <h4 className="row">{description}</h4>}
                                                <div className={"row order-row" + (has_extras ? " has-details" : "") + (openOrderDetailsIndex == idx ? " active" : "")} onClick={e => {
                                                    setOpenOrderDetailsIndex(idx);
                                                }}>
                                                    <div className="col-2-md col">{order.name}</div>
                                                    <div className="col-3-md col">{order.email}</div>
                                                    {order.phone && <div className="col-2-md col">{Helper.FormatPhoneNumber(order.phone)}</div>}
                                                    <div className="col-2-md col right-md">{order.pax} persona{order.pax == 1 ? "" : "s"}</div>
                                                    {order.details.time_relevant > 0 && <div className="col-2-md right-md">{order.booked_slot}</div>}
                                                    <div className="col-1-md col right-md">{Helper.FormatAmount(order.total / 100)}</div>
                                                    <span className="delete-button" onClick={e => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        showDialog.current({
                                                            prompt: "¿Seguro que deseas borrar esta reserva?",
                                                            onAccepted: () => {
                                                                setLoading(true);
                                                                console.trace("Ran")
                                                                // Orders.PartnerDeleteOrder(order.id).then(() => {
                                                                //     Session.GetPartnerCalendar(accountEvents[selectedAccountEventIndex].id).then(calendar => {
                                                                //         setOrdersCalendar(calendar);
                                                                //         setOrders(calendar[Helper.GetISODate(selectedDate)]);
                                                                //         setOpenOrderDetailsIndex(-1);
                                                                //         setLoading(false);
                                                                //     });
                                                                // });
                                                            }
                                                        });
                                                    }}><i className="far fa-trash-alt"></i></span>
                                                </div>
                                                {openOrderDetailsIndex == idx && <div className="order-details row">
                                                    <div className="modal-close-button tock-icon" onClick={e => { setOpenOrderDetailsIndex(-1) }}></div>
                                                    {has_extras && order.preorder_selection.map((item, eidx) => {
                                                        const compositeIndex = idx + "-" + eidx;
                                                        return <div className="extras-row" key={eidx}>
                                                            {orderDetailsEdittingQuantity == compositeIndex && <input className="editting-quantity" pattern="[0-9]" defaultValue={item.quantity} onKeyPress={e => {
                                                                if (e.key == "Enter") {
                                                                    setLoading(true);
                                                                    Orders.PartnerChangeOrderExtrasQuantity(order.id, eidx, e.target.value).then(() => {
                                                                        Session.GetPartnerCalendar(accountEvents[selectedAccountEventIndex].id).then(calendar => {
                                                                            setOrdersCalendar(calendar);
                                                                            setOrders(calendar[Helper.GetISODate(selectedDates[0])]);
                                                                            setLoading(false);
                                                                            setOrderDetailsEdittingQuantity("");
                                                                        });
                                                                    });
                                                                }
                                                            }} onBlur={e => {
                                                                setLoading(true);
                                                                Orders.PartnerChangeOrderExtrasQuantity(order.id, eidx, e.target.value).then(() => {
                                                                    Session.GetPartnerCalendar(accountEvents[selectedAccountEventIndex].id).then(calendar => {
                                                                        setOrdersCalendar(calendar);
                                                                        setOrders(calendar[Helper.GetISODate(selectedDates[0])]);
                                                                        setLoading(false);
                                                                        setOrderDetailsEdittingQuantity("");
                                                                    });
                                                                });
                                                            }} />}
                                                            {orderDetailsEdittingQuantity != compositeIndex && <div className="quantity" onClick={e => {
                                                                setOrderDetailsEdittingQuantity(compositeIndex);
                                                                setTimeout(() => {
                                                                    $(".editting-quantity").trigger("focus");
                                                                    setTimeout(() => $(".editting-quantity")[0].select(), 50);
                                                                }, 100);
                                                            }}><i className="fas fa-pencil-alt"></i><span>{item.quantity}</span></div>}
                                                            <div className="name">{item.name}</div>
                                                        </div>;
                                                    })}
                                                    <span className="add-order-extras-button" onClick={e => {
                                                        addExtrasModalRef.current.setState({
                                                            order: order
                                                        });
                                                        setTimeout(() => addExtrasModalRef.current.open(), 1);
                                                    }}><i className="fas fa-plus"></i></span>
                                                </div>}
                                            </div>;
                                        })
                                    }
                                    <Modal
                                        ref={ref => addOrderModalRef.current = ref}
                                        title="Crear reserva"
                                        onOpen={modal => {
                                            modal.setState({ loading: true });
                                            Events.GetDates(modal.state.event.id, Helper.GetISODate(modal.state.selectedDates[0]).split(" ")[0]).then(dates => {
                                                modal.setState({
                                                    dates: dates.filter(d => d.pax > 0),
                                                    loading: false,
                                                    name: "",
                                                    email: "",
                                                    phone: "",
                                                    pax: "",
                                                    selectionLength: 1
                                                });
                                            });
                                        }}
                                        onClose={modal => {

                                        }}
                                        renderContent={modal => {
                                            if (modal.state.loading) {
                                                return <LoadingIndicator />;
                                            }

                                            return <div className="add-order-modal container">
                                                <div className="row">
                                                    <div className="col">
                                                        <FormInput onChange={value => modal.setState({ name: value })} label="Nombre y apellidos" required />
                                                    </div>
                                                    <div className="col">
                                                        <FormInput onChange={value => modal.setState({ email: value })} label="Email" type="email" required />
                                                    </div>
                                                    <div className="col">
                                                        <FormInput onChange={value => modal.setState({ phone: value })} label="Teléfono" type="phone" required />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <FormInput onChange={value => modal.setState({ pax: value })} label="Personas" type="number" initialValue={1} required />
                                                    </div>
                                                    <div className="col">
                                                        <FormInput onChange={value => modal.setState({
                                                            availabilityID: value,
                                                            datetime: modal.state.dates.find(d => d.id == value)
                                                        })} type="select" label="Fecha" options={modal.state.dates.map(item => {
                                                            return { value: item.id, label: Helper.FormatDate(Helper.CreateDateCompatible(item.slot), Helper.DATE_VERBOSE_LONG) + " " + item.slot.split(" ")[1] }
                                                        })} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><button className="btn btn-brown btn-small" onClick={e => {
                                                        modal.setState({
                                                            loading: true
                                                        });
                                                        Orders.CreateCustomOrder({
                                                            event: modal.state.event.slug,
                                                            event_id: modal.state.availabilityID,
                                                            booked_date: modal.state.datetime,
                                                            booked_slot: modal.state.datetime.split(" ")[1],
                                                            pax: modal.state.pax,
                                                            selection_length: modal.state.selectionLength,
                                                            name: modal.state.name,
                                                            phone: modal.state.phone,
                                                            email: modal.state.email
                                                        }).then(() => {
                                                            updateCalendarData();
                                                            modal.close();
                                                        });
                                                    }}>Crear</button></div>
                                                </div>
                                            </div>;
                                        }}
                                    />
                                    <Modal
                                        ref={ref => addExtrasModalRef.current = ref}
                                        title="Añadir extras"
                                        onOpen={modal => {
                                            modal.setState({ loading: true });
                                            Events.GetEventSlot(modal.state.order.event_id).then(event => {
                                                modal.setState({
                                                    loading: false,
                                                    event,
                                                    selection: JSON.parse(event.preorder_menu_alt || event.preorder_menu || "{}")
                                                });
                                            });
                                        }}
                                        renderContent={modal => {
                                            if (modal.state.loading || !modal.state.selection) {
                                                return <LoadingIndicator />;
                                            }

                                            return <div className="add-order-extras-modal">
                                                {Object.keys(modal.state.selection).map((categoryName, idx) => {
                                                    return <div className="category" key={idx}>
                                                        <h2>{categoryName}</h2>
                                                        {modal.state.selection[categoryName].items.map((item, idx) => {
                                                            return <div className="item-row" key={idx}>
                                                                <div className="row-name">{item.name}</div>
                                                                <div className="row-quantity">
                                                                    <span onClick={e => {
                                                                        const newSelection = { ...modal.state.selection };
                                                                        newSelection[categoryName].items[idx].qty = Math.max(0, (newSelection[categoryName].items[idx].qty || 0) - 1);
                                                                        modal.setState({
                                                                            selection: newSelection
                                                                        });
                                                                    }}><img src="/static/icons/minus.svg" /></span>
                                                                    <div className="qty">{item.qty || 0}</div>
                                                                    <span onClick={e => {
                                                                        const newSelection = { ...modal.state.selection };
                                                                        newSelection[categoryName].items[idx].qty = (newSelection[categoryName].items[idx].qty || 0) + 1;
                                                                        modal.setState({
                                                                            selection: newSelection
                                                                        });
                                                                    }}><img src="/static/icons/plus.svg" /></span>
                                                                </div>
                                                            </div>;
                                                        })}
                                                    </div>;
                                                })}
                                                <div className="confirm-button btn btn-brown" onClick={e => {
                                                    e.preventDefault();

                                                    setLoading(true);
                                                    Orders.PartnerAddExtrasToOrder(modal.state.order.id, modal.state.selection).then(() => {
                                                        Session.GetPartnerCalendar(accountEvents[selectedAccountEventIndex].id).then(calendar => {
                                                            setOrdersCalendar(calendar);
                                                            setOrders(calendar[Helper.GetISODate(selectedDates[0])]);
                                                            setLoading(false);
                                                            modal.close();
                                                        });
                                                    });
                                                }}>CONFIRMAR</div>
                                            </div>;
                                        }}
                                    />
                                </div>;
                            }
                    },
                    {
                        caption: "Disponibilidad",
                        render: () => <div className="container">
                            <div className="row">
                                <div className="col">
                                    <Calendar
                                        allowMultipleSelection={true}
                                        months={Helper.IsResponsive() ? 1 : 3}
                                        changeDateRef={ref => changeDate.current = ref}
                                        allowPast={true}
                                        allowUnavailableSelection={true}
                                        availabilityCalendar={availabilityCalendar}
                                        onSelectionChanged={dates => {
                                            setSelectedDates(dates);
                                            setOpenOrderDetailsIndex(-1);
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                selectedDates.length > 0 && <div className="row">
                                    {selectedDates.length == 1 && <div className="col"><h3>{Helper.UCFirst(Helper.FormatDate(selectedDates[0], Helper.DATE_VERBOSE_LONGEST))}</h3></div>}
                                    {selectedDates.length > 1 && <div className="col"><h3>Múltiples fechas</h3></div>}
                                    <span className="btn add-availability-button" onClick={e => {
                                        editAvailabilityModalRef.current.setState({ availability: [], event: accountEvents[selectedAccountEventIndex] });
                                        setTimeout(() => editAvailabilityModalRef.current.open(), 1);
                                    }}>Crear nueva</span>
                                </div>
                            }
                            {
                                selectedDates.length > 0 && filterAvailabilityCalendar(selectedDates)?.map((availabilityGroup, idx) => {
				    				const availability = availabilityGroup[0];
                                    const price = availability.price_alt != -1 ? availability.price_alt : availability.event.price;

                                    return <div className="availability-row" key={idx}>
										<div className="row">
											<div className={Helper.IsResponsive() ? "col-2" : "col-1"}>
												{availability.slot.split(" ")[1].substr(0, 5)}
											</div>
											<div className="col">
												{availability.availability_description}
											</div>
										</div>
										<div className="icon-actions">
											<MaterialIcon name="edit_square" onClick={e => {
												editAvailabilityModalRef.current.setState({
													availability: availabilityGroup,
													event: accountEvents[selectedAccountEventIndex]
												});
												setTimeout(() => editAvailabilityModalRef.current.open(), 1);
											}} />
											<MaterialIcon onClick={e => {
												if (confirm(availabilityGroup.length > 1 ? "¿Seguro que deseas borrar estas fechas?" : "¿Seguro que deseas borrar esta fila?")) {
													Events.PartnerDeleteAvailabilities(availabilityGroup).then(() => {
														updateCalendarData();
													});
												}
											}} name="delete" style={{ marginLeft: "1em", marginRight: "0.5em", top: 2, position: "relative" }} />
										</div>
                                    </div>
                                })
                            }
                            {
                                selectedDates.length > 0 && !filterAvailabilityCalendar(selectedDates) && <div className="row">
                                    <div className="col">No hay disponibilidad definida para esta fecha.</div>
                                </div>
                            }

                            <Modal
                                ref={ref => editAvailabilityModalRef.current = ref}
                                title="Disponibilidad"
                                renderContent={modal => {
									const fullBooking = modal.state.availability.length ? modal.state.availability[0].full_booking == 1 : false;
                                    const selectedTime = (modal.state.availability[0]?.slot?.split(" ")[1].substr(0, 5)) || "";
                                    const duration = (Helper.MinutesToTimeString(modal.state.availability[0]?.duration)) || "";
									const price = ((modal.state.availability.length ? modal.state.availability[0].price_alt : "") && (modal.state.availability.length ? modal.state.availability[0].price_alt != -1 : "")) ? modal.state.availability?.price_alt : event?.price;

                                    return <div className="add-availability-modal container">
										<Bloque className="columnas">
											<div className="columna row">
												<div className="col">
													<MaterialIcon name="schedule" style={{ marginRight: "0.25em" }} />
													<TimeInput defaultValue={selectedTime} required onChange={value => {
														modal.setState({ time: value });
													}} />
													<MaterialIcon name="update" style={{ marginRight: "0.25em", marginLeft: "1em" }} />
													<TimeInput defaultValue={duration} required onChange={value => {
														modal.setState({ duration: value });
													}} />
													<Field style={{ marginLeft: "2.25em" }} defaultValue={modal.state.availability.length ? modal.state.availability[0].pax : ""} required min={1} type="number" min={1} max={999} onChange={value => {
														modal.setState({ pax: value });
													}} />
													<Field style={{ marginLeft: "1.5em" }} defaultValue={price} required type="currency" onChange={value => {
														modal.setState({ price });
													}} />
													<MaterialIcon style={{marginLeft: "auto"}} large={true} tooltip="Alojamiento completo" name={fullBooking ? "toggle_on" : "toggle_off"} onClick={e => modal.setState({ fullBooking: fullBooking ? 1 : 0 })} />
												</div>
											</div>
										</Bloque>
										<Bloque className="columnas">
											<div className="columna">
												<div className="row">
													<div className="col"><Field style={{flex: 1, marginRight: 15}} placeholder="Dirección" type="string" defaultValue={modal.state.availability.length ? modal.state.availability[0].address_alt : ""} required onChange={e => {
														modal.setState({ address: e.target.value });
													}} /><Field style={{flex: 1}} placeholder="Ciudad" type="string" defaultValue={modal.state.availability.length ? modal.state.availability[0].city_alt : ""} required onChange={e => {
														modal.setState({ ciudad: e.target.value });
													}} /></div>
												</div>
												<div className="row">
													<div className="col" style={{alignItems: "flex-start", marginTop: 15}}>
														<InputSelect placeholder="Evento" style={{flex: 1, marginRight: 15}} options={availabilityDescriptions} defaultValue={modal.state.availability.length ? modal.state.availability[0].availability_description : ""} required onChange={value => {
															modal.setState({ availabilityDescription: value });
														}} />
														<Field type="text" style={{flex: 1}} placeholder="Descripción" defaultValue={modal.state.availability.length ? modal.state.availability[0].description : ""} onChange={e => {
															modal.setState({ description: e.target.value });
														}} />
													</div>
												</div>
											</div>
										</Bloque>
                                        <button className="btn btn-brown boton-guardar" onClick={e => {
											const promises = [];
											const leftoverAvailability = [...modal.state.availability];
											const leftoverDates = [...selectedDates];

											selectedDates.forEach(selectedDate => {
												if (modal.state.availability) modal.state.availability.forEach(availability => {
													if (
														availability.slot.split(" ")[0] == Helper.GetISODate(selectedDate) &&
														availability.slot.split(" ")[1] == modal.state.time
													) {
														promises.push(
															Events.PartnerAlterAvailability({
																id: modal.availability.id,
																event: modal.state.event.slug,
																slot: Helper.GetISODate(selectedDate) + " " + modal.state.time,
																duration: modal.state.duration,
																pax: modal.state.pax,
																full_booking: modal.state.fullBooking || 0,
																price_alt: parseFloat(modal.state.price.replace(".", "").replace(",", ".")) * 100,
																address_alt: modal.state.address || "",
																city_alt: modal.state.city || "",
																description_alt: modal.state.description || "",
																availability_description: modal.state.availabilityDescription || ""
															})
														);

														leftoverAvailability.splice(leftoverAvailability.findIndex(a => a.id == modal.availability.id), 1);
														leftoverDates.splice(leftoverDates.findIndex(d => d.valueOf() == selectedDate.valueOf()), 1);
													}
												});
											});

											leftoverDates.forEach(date => {
												promises.push(
													Events.PartnerCreateAvailability({
														event: modal.state.event.slug,
														slot: Helper.GetISODate(date) + " " + modal.state.time,
														duration: modal.state.duration,
														pax: modal.state.pax,
														full_booking: modal.state.fullBooking || 0,
														price_alt: parseFloat(modal.state.price.replace(".", "").replace(",", ".")) * 100,
														address_alt: modal.state.address || "",
														city_alt: modal.state.city || "",
														description_alt: modal.state.description || "",
														availability_description: modal.state.availabilityDescription || ""
													})
												);
											});

											leftoverAvailability.forEach(availability => {
												promises.push(
													Events.PartnerDeleteAvailability(availability.id)
												);
											});

											Promise.all(promises).then(() => {
												modal.close();
											});
										}}>Guardar</button>
                                    </div>;
                                }}
                            />
                        </div>
                    }
                ]} />
            </div>
        </div>
        <ConfirmationDialog onShowRef={ref => showDialog.current = ref} />
    </div>;
}
