import AdminService from "./Admin";
import { APIRequest } from "./API";

export default class ContentHelper {
    static async GetString(slug) {
        const response = await APIRequest("cms/get", { slug });

        if (!response.status || !response.data) {
            return "";
        }

        return response.data.content;
    }

    static async GetLogoImg(className, height, styles=null) {
        const response = await APIRequest("cms/get-logo");
        return <img className={className} height={height} style={{ width: "auto", height, ...styles}} src={"/static/images/" + response.data} />;
    }

    static async GetSocialMediaLinks() {
        const response = await APIRequest("cms/get-social-media-networks");
        const links = JSON.parse(response.data);
        return <>
            {links.map((network, idx) => {
                return <a key={idx} rel="noreferrer" href={network.url} target="_blank">{network.name}</a>;
            })}
        </>;
    }


    static async GetStrings(slugsArray) {
        const response = await APIRequest("cms/get-multiple", { slugsArray });

        if (!response.status || !response.data) {
            return "";
        }

        const output = {};
        for (let item of response.data) {
            output[item.slug] = item.content;
        }

        return output;
    }

    static async GetNumber(slug) {
        const string = await ContentHelper.GetString(slug);
        return parseFloat(string);
    }

    static async GetFAQAll() {
        const response = await APIRequest("cms/faq-get-all");
        const keys = response.data.filter(item => item.slug.match(/faq-\d+-titulo/gi)).map(item => item.slug);
        const titles = response.data.filter(item => item.slug.match(/faq-\d+-titulo/gi)).map(item => item.content);
        const content = response.data.filter(item => item.slug.match(/faq-\d+-contenido/gi)).map(item => item.content);
        return keys.map((k, i) => {
            return {
                titulo: titles[i],
                contenido: content[i],
                key: k
            };
        });
    }

}
