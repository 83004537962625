import { useEffect, useState, useContext } from "react";
import Bloque from "./AdminBloque";
import Field from "./Field";

import { DialogContext } from "../context/DialogContext";

import AdminService from "../services/Admin";
import AdminBar from "./AdminBar";

export default function AdminEmails(props) {
	const [welcomeText, setWelcomeText] = useState(null);
	const [emailChangeText, setEmailChangeText] = useState(null);
	const [orderBeforeDetails, setOrderBeforeDetails] = useState(null);
	const [orderAfterDetails, setOrderAfterDetails] = useState(null);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		AdminService.GetSettings("MAIL_WELCOME_TEXT").then((text) => setWelcomeText(text));
		AdminService.GetSettings("MAIL_CHANGE_EMAIL").then((text) => setEmailChangeText(text));
		AdminService.GetSettings("MAIL_ORDER_BEFORE_DETAILS").then((text) => setOrderBeforeDetails(text));
		AdminService.GetSettings("MAIL_ORDER_AFTER_DETAILS").then((text) => setOrderAfterDetails(text));

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	return (
		<div className="admin-page admin-emails">
			<AdminBar onSave={notifySaved => {
				dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
					if (!confirmed) {
						notifySaved(false);
						return;
					}

					Promise.all([AdminService.SetSettings("MAIL_WELCOME_TEXT", welcomeText), AdminService.SetSettings("MAIL_CHANGE_EMAIL", emailChangeText), AdminService.SetSettings("MAIL_ORDER_BEFORE_DETAILS", orderBeforeDetails), AdminService.SetSettings("MAIL_ORDER_AFTER_DETAILS", orderAfterDetails)]).then(() => {
						notifySaved(true);
					});
				});
			}} />
			<Bloque className="columnas">
				<div className="columna">
					<h2>Email de bienvenida</h2>
					{welcomeText !== null && <Field type="text" onChange={(value) => setWelcomeText(value)} defaultValue={welcomeText} />}
				</div>
				<div className="columna">
					<h2>Cambio de email</h2>
					{emailChangeText !== null && <Field type="text" onChange={(value) => setEmailChangeText(value)} defaultValue={emailChangeText} />}
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<h2>Introducción al email de nuevo pedido</h2>
					{orderBeforeDetails !== null && <Field type="text" onChange={(value) => setOrderBeforeDetails(value)} defaultValue={orderBeforeDetails} />}
				</div>
				<div className="columna">
					<h2>Texto en el email de nuevo pedido</h2>
					{orderAfterDetails !== null && <Field type="text" onChange={(value) => setOrderAfterDetails(value)} defaultValue={orderAfterDetails} />}
				</div>
			</Bloque>
		</div>
	);
}
