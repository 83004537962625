import { useState, useEffect, useContext } from "react";
import LoadingIndicator from "./LoadingIndicator";
import ContentHelper from "../services/ContentHelper";
import TextEditor from "./TextEditor";
import AdminBar from "./AdminBar";
import AdminService from "../services/Admin";
import { DialogContext } from "../context/DialogContext";

export default function AdminPaginaHTML(props) {
    const [content, setContent] = useState(null);
    const dialogContext = useContext(DialogContext);

    const contentSlug = props.contentSlug;
    useEffect(() => {
        if (!contentSlug) return;
        ContentHelper.GetString(contentSlug).then(content => setContent(content));
    }, [contentSlug]);

    if (content === null) {
        return <LoadingIndicator />;
    }

    return <div className={"admin-page admin-pagina-html " + props.contentSlug}>
        <AdminBar onSave={notifySaved => {
            dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
                if (!confirmed) {
                    notifySaved(false);
                    return;
                }
                
                AdminService.SaveContent(contentSlug, content).then(() => {
                    notifySaved(true);
                });
            });
        }} />
        <TextEditor initialValue={content} onChange={value => { setContent(value) }} />
    </div>;
}
