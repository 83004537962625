import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useMatch } from "react-router-dom";
import Session from "../services/Session";
import { HeaderContext } from "../services/HeaderContext";
import $ from "jquery";

import ContentHelper from "../services/ContentHelper";
import Icon from "./Icon";

export default function MainMenu(props) {
	const [logo, setLogo] = useState();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const headerContext = useContext(HeaderContext);
	const [customStaticPageURL, setCustomStaticPageURL] = useState(null);
	const [customStaticPageCaption, setCustomStaticPageCaption] = useState(null);
	const isAdminPage = useMatch("/admin/*");

	function handleScroll(e) {
		if (window.scrollY > 1) {
			document.body.classList.add("scrolled");
		} else {
			document.body.classList.remove("scrolled");
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		handleScroll();

		Session.CheckIsLoggedIn().then((isLoggedIn) => {
			setIsLoggedIn(isLoggedIn);
		});

		$(function ($) {
			$(window).on("click", function (e) {
				const on_header = $(e.target).closest("header.desktop").length && !$(e.target).closest("header.desktop li:not(.user-account-button-container").length;
				if (!on_header) {
					$("header.desktop .navbar-container").removeClass("active");
				}
			});

			$(".user-account-button-close").on("click", function (e) {
				$("header.desktop .navbar-container").removeClass("active");
			});
		});

		ContentHelper.GetLogoImg("logo").then((element) => setLogo(element));

		Promise.all([ContentHelper.GetString("custom_static_page_url"), ContentHelper.GetString("custom_static_page_caption")]).then(([url, caption]) => {
			if (url && url.length) setCustomStaticPageURL(url);
			else setCustomStaticPageURL("/para-empresas");

			if (caption && caption.length) setCustomStaticPageCaption(caption);
			else setCustomStaticPageCaption("Para empresas");
		});

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<nav className="navbar navbar-expand-md">
			<div className="container full-width mw-95 py-1 py-lg-0">
				<div className="d-none d-lg-block">
					<Link className="navbar-brand" to="/">
						{logo}
					</Link>
				</div>
				<div className="navbar-collapse collapse" id="navbarResponsive">
					<ul className="navbar-nav mr-auto"></ul>
					<div className="navbar-container">
						<ul className="navbar-nav">
							{headerContext.headerButtons || !isAdminPage && (
								<li>
									{customStaticPageURL != null && (
										<a target={customStaticPageURL.match(/^http/) ? "_blank" : "_self"} className="nav-link nav-btn" href={customStaticPageURL}>
											{customStaticPageCaption}
										</a>
									)}
								</li>
							)}
							<li className="user-account-button-container">
								<Icon
									name={isLoggedIn ? "user-selected" : "user"}
									size="2"
									className="user-account-button"
									onMouseEnter={() => {}}
									onMouseLeave={() => {}}
									onClick={() => {
										$("header.desktop .navbar-container").toggleClass("active");
									}}
								/>
							</li>
						</ul>
						<ul className="navbar-nav user-actions">
							{Session.RenderUserActions(null, true)}
							<li className="user-account-button-close">
								<div className="tock-icon"></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}
