import { useEffect, useState, useContext, useCallback } from "react";
import AdminBar from "./AdminBar";
import { APIAdminRequest } from "../services/API";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import Events from "../services/Events";
import Icon from "./Icon";
import Bloque from "./AdminBloque";
import { DialogContext } from "../context/DialogContext";

export default function AdminNegocios(props) {
	const [venueTypes, setVenueTypes] = useState([]);
	const [blank, setBlank] = useState(false);
	const [events, setEvents] = useState([]);
	const dialogContext = useContext(DialogContext);

	function changeVenueType(idx, type) {
		const newTypes = [...venueTypes];
		newTypes[idx] = type;
		newTypes.sort((a, b) => a.order - b.order);
		setVenueTypes(newTypes);
	}

	function reload() {
		setBlank(true);
		setTimeout(() => setBlank(false), 100);
	}

	useEffect(() => {
		APIAdminRequest("get-types").then((response) => {
			setVenueTypes(response.data);
		});
		Events.GetAll().then((events) => setEvents(events));

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}
			APIAdminRequest("save-types", { types: venueTypes }).then((response) => {
				notifySaved(true);
			});
		});
	}, [venueTypes]);

	if (blank) return null;

	return (
		<div className="admin-page admin-negocios">
			<AdminBar onSave={save} />
			<div className="header">
				<a
					href="#"
					className="btn"
					onClick={(e) => {
						e.preventDefault();
						let newTypes = [...venueTypes];
						if (newTypes.find((v) => v.order == 0)) {
							for (let i = 0; i < newTypes.length; i++) {
								newTypes[i].order++;
							}
						}
						newTypes.unshift({
							id: -Math.random(),
							type: "",
							plural: "",
							order: 0,
							main: 0,
							description: ""
						});
						setVenueTypes(newTypes);
					}}>
					Nueva categoría
				</a>
			</div>
			<Bloque className="filas">
				{venueTypes.map((type, idx) => {
					return (
						<div className="fila venue-type" key={type.id}>
							<div className="row-actions">
								<div>
									<a
										className="highlight-type"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											if (type.main == 0) type.main = 1;
											else type.main = 0;
											changeVenueType(idx, type);
										}}>
										{type.main == 0 ? <MaterialIcon name="star" /> : <MaterialIcon name="star" />}
									</a>
									<div className="icon-tooltip">Destacado</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-up"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order = Math.max(0, type.order - 1);
											for (let i = 0; i < venueTypes.length; i++) {
												if (i == idx) continue;
												if (venueTypes[i].order == type.order) venueTypes[i].order = previousOrder;
											}
											changeVenueType(idx, type);
										}}>
										<MaterialIcon name="arrow_circle_up" />
									</a>
									<div className="icon-tooltip">Mover hacia arriba</div>
								</div>
								<div>
									<a
										href="#"
										className="move-type-down"
										onClick={(e) => {
											e.preventDefault();
											const previousOrder = type.order;
											type.order++;
											for (let i = 0; i < venueTypes.length; i++) {
												if (i == idx) continue;
												if (venueTypes[i].order == type.order) venueTypes[i].order = previousOrder;
											}
											changeVenueType(idx, type);
										}}>
										<MaterialIcon name="arrow_circle_down" />
									</a>
									<div className="icon-tooltip">Mover hacia abajo</div>
								</div>
								<Field
									type="string"
									placeholder="Nombre de la categoría"
									defaultValue={type.type}
									onChange={(value) => {
										type.type = value;
										changeVenueType(idx, type);
									}}
								/>
								<Field
									type="string"
									placeholder="Título de la sección"
									defaultValue={type.plural}
									onChange={(value) => {
										type.plural = value;
										changeVenueType(idx, type);
									}}
								/>
								<div className="delete-container">
									<a
										className="delete"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											const newTypes = [...venueTypes];
											newTypes.splice(idx, 1);
											setVenueTypes(newTypes);
										}}>
										<MaterialIcon name="delete" />
									</a>
									<div className="icon-tooltip">Borrar categoría</div>
								</div>
							</div>

							<div className="evento-grid">
								{events
									.filter((v) => v.tipo.type == type.type)
									.map((event, idx) => {
										return (
											<div className="carrusel-main-evento" key={idx} onClick={(e) => (location.href = "/admin/negocios/" + event.id)}>
												<div className="image-container" style={{ backgroundImage: "url(/static/images/eventos/" + event.image_url + ")" }}>
													{event.iconos && (
														<div className="icons">
															{event.iconos.map((icono, idx) => {
																return (
																	<div
																		style={{ position: "relative" }}
																		onClick={(e) => {
																			e.preventDefault();
																			e.stopPropagation();
																			return false;
																		}}
																		key={idx}>
																		<Icon name={icono.name} pixelSize={32} />
																		<div className="icon-tooltip">{icono.description}</div>
																	</div>
																);
															})}
														</div>
													)}
												</div>
												<div className="details">
													<div className="name">{event.name}</div>
													<div className="city">{event.city}</div>
												</div>
											</div>
										);
									})}
								<div className="carrusel-main-evento add-new" key={idx} onClick={(e) => (location.href = "/admin/negocios/0")}>
									<MaterialIcon name="add_circle" />
								</div>
							</div>
						</div>
					);
				})}
			</Bloque>
		</div>
	);
}
