import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import ContentHelper from '../services/ContentHelper';

export default function Footer(props) {
	const [logo, setLogo] = useState();
	const [socialMediaLinks, setSocialMediaLinks] = useState();

	useEffect(() => {
		ContentHelper.GetLogoImg("logo", 50, {marginRight: "auto"}).then(element => setLogo(element));
		ContentHelper.GetSocialMediaLinks().then(links => setSocialMediaLinks(links));
	}, []);
	return <footer>
			<div className="container full-width">
				<div className="row">
					<div className="col-md-6 menu-footer">
						<Link className="nav-link" to="/preguntas-frecuentes">FAQ</Link>
						<Link className="nav-link" to="/contacto">CONTACTO</Link>
						{socialMediaLinks}
					</div>
					<div className="col-md-6">

					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						{logo}
					</div>
					<div className="col-md-6 right footer-links">
						<div className="links">
							<Link to="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</Link>
							<Link to="/politica-de-privacidad">PRIVACIDAD</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>;
}
