import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import AdminService from "../services/Admin";
import AdminUsuarios from "./AdminUsuarios";
import AdminNegocios from "./AdminNegocios";
import AdminEditarNegocio from "./AdminEditarNegocio";
import AdminEditarEvento from "./AdminEditarEvento";
import AdminHome from "./AdminHome";
import AdminFAQ from "./AdminFAQ";
import AdminGeneral from "./AdminGeneral";
import AdminContacto from "./AdminContacto";
import AdminPaginaHTML from "./AdminPaginaHTML";
import AdminPaginaEstatica from "./AdminPaginaEstatica";
import MaterialIcon from "./MaterialIcon";
import LoadingIndicator from "./LoadingIndicator";
import AdminEmails from "./AdminEmails";
import AdminReservasDisponibilidad from "./AdminReservasDisponibilidad";
import AdminPermisos from "./AdminPermisos";
import AdminEstadisticas from "./AdminEstadisticas";

import Session from "../services/Session";

import { PermissionsContext } from "../context/PermissionsContext";
import { SessionContext } from "../context/SessionContext";

export default function Admin(props) {
	const params = useParams();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState("home");
	const [isAdmin, setIsAdmin] = useState(null);
	const [blank, setBlank] = useState();
	const [permissions, setPermissions] = useState(null);
	const sessionContext = useContext(SessionContext);

	useEffect(() => {
		AdminService.GetUserPermissions().then((permissions) => {
			setPermissions(permissions);
		});

		document.body.classList.add("admin");

		return () => {
			document.body.classList.remove("admin");
		};
	}, []);

	function renderCurrentPage() {
		if (!permissions) return null;

		switch (currentPage) {
			default:
			case "home":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Contenidos de portada";
				return <AdminHome />;
			case "faq":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Preguntas frecuentes";
				return <AdminFAQ />;
			case "contacto":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Formulario de contacto";
				return <AdminContacto />;
			case "terminos":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Términos y condiciones";
				return <AdminPaginaHTML contentSlug="terminos-y-condiciones" />;
			case "privacidad":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Política de privacidad";
				return <AdminPaginaHTML contentSlug="politica-de-privacidad" />;
			case "pagina":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Página estática";
				return <AdminPaginaEstatica />;
			case "estadisticas":
				window.document.title = "Estadísticas";
				return <AdminEstadisticas />;
			case "reservas":
				if (!permissions.data.manage_orders && !permissions.data.manage_availability) return null;
				window.document.title = "Reservas";
				return <AdminReservasDisponibilidad />;
			case "emails":
				if (!permissions.data.edit_email_contents) return null;
				window.document.title = "E-mails";
				return <AdminEmails />;
			case "usuarios":
				if (!permissions.data.manage_users) return null;
				window.document.title = "Listado de usuarios";
				return <AdminUsuarios />;
			case "negocios":
				if (!permissions.data.edit_negocios) return null;
				if (params.args2) {
					window.document.title = "Editar evento";
					return <AdminEditarEvento eventID={params.args2} negocioID={params.args} />;
				}
				if (params.args) {
					window.document.title = "Editar negocio";
					return <AdminEditarNegocio negocioID={params.args} />;
				} else {
					window.document.title = "Listado de negocios";
					return <AdminNegocios />;
				}
			case "general":
				if (!permissions.data.edit_site_contents) return null;
				window.document.title = "Ajustes generales";
				return <AdminGeneral />;
			case "permisos":
				if (!permissions.data.manage_permissions) return null;
				window.document.title = "Permisos";
				return <AdminPermisos />;
		}
	}

	function openPage(pageName) {
		setBlank(true);
		$(".sidebar a").removeClass("active");
		$(".sidebar a.link-" + pageName).addClass("active");
		setTimeout(() => {
			setCurrentPage(pageName);
			setBlank(false);
		}, 100);
	}

	if (props.drawHeaderButtons) {
		props.drawHeaderButtons(() => {
			return <div></div>;
		});
	}

	useEffect(() => {
		AdminService.IsUserAdmin().then((isAdmin) => {
			setIsAdmin(isAdmin);
		});

		$("header").addClass("simple admin");

		return () => {
			$("header").removeClass("simple admin");
		};
	}, []);

	if (!sessionContext.session) {
		Session.RequireLogin();
	}

	if (!permissions || isAdmin === null) {
		return <LoadingIndicator />;
	}

	if (!blank && params.page && currentPage != params.page) {
		openPage(params.page);
	}

	let returnLocation = location.pathname.split("/").slice(0, -1).join("/");
	if (returnLocation == "/admin") returnLocation = "/admin/portada";
	if (location.pathname == "/admin/portada" || returnLocation == location.pathname) returnLocation = "/";

	return (
		<PermissionsContext.Provider value={{ permissions }}>
			<div className="admin">
				<div className="sidebar">
					<div className="top">
						<Link to={returnLocation} className="link-volver">
							<MaterialIcon name="arrow_back" /> Volver
						</Link>
					</div>
					{permissions.data.edit_site_contents && (
						<div className="middle">
							<Link to="/admin/home" className="link-home">
								<MaterialIcon name="home" /> Portada
							</Link>
							<Link to="/admin/faq" className="link-faq">
								<MaterialIcon name="live_help" /> FAQ
							</Link>
							<Link to="/admin/contacto" className="link-contacto">
								<MaterialIcon name="contact_mail" /> Contacto
							</Link>
							<Link to="/admin/terminos" className="link-terminos">
								<MaterialIcon name="policy" /> Términos y condiciones
							</Link>
							<Link to="/admin/privacidad" className="link-privacidad">
								<MaterialIcon name="admin_panel_settings" /> Política de privacidad
							</Link>
							<Link to="/admin/pagina" className="link-pagina">
								<MaterialIcon name="view_quilt" /> Página estática
							</Link>
						</div>
					)}
					<div className="bottom">
						{(permissions.data.manage_orders || permissions.manage_availability) && (
							<Link to="/admin/reservas" className="link-reservas">
								<MaterialIcon name="calendar_month" /> Reservas
							</Link>
						)}
						{permissions.data.edit_negocios && (
							<Link to="/admin/negocios" className="link-negocios">
								<MaterialIcon name="local_convenience_store" /> Negocios
							</Link>
						)}
						<Link to="/admin/estadisticas" className="link-estadisticas">
							<MaterialIcon name="insert_chart" /> Estadísticas
						</Link>
						{permissions.data.manage_users && (
							<Link to="/admin/usuarios" className="link-usuarios">
								<MaterialIcon name="account_box" /> Usuarios
							</Link>
						)}
						{permissions.data.edit_email_contents && (
							<Link to="/admin/emails" className="link-emails">
								<MaterialIcon name="local_post_office" /> E-mails
							</Link>
						)}
						{permissions.data.manage_permissions && (
							<Link to="/admin/permisos" className="link-permisos">
								<MaterialIcon name="flag" /> Permisos
							</Link>
						)}
						{permissions.data.edit_site_contents && (
							<Link to="/admin/general" className="link-general">
								<MaterialIcon name="settings" /> General
							</Link>
						)}
					</div>
				</div>
				<div className="content">{!blank && renderCurrentPage()}</div>
			</div>
		</PermissionsContext.Provider>
	);
}
