require("../../css/combo-select.css");

import { useState, useEffect } from 'react';

import Helper from '../services/Helper';
import MaterialIcon from './MaterialIcon';

export default function ComboSelect(props) {
	const [ expanded, setExpanded ] = useState(false);
	const [ selectedOptionIndex, setSelectedOptionIndex ] = useState(props.options.findIndex(el => typeof props.defaultValue !== "object" ? el.value == props.defaultValue : el == props.defaultValue));
	const [ maxPopupHeight, setMaxPopupHeight ] = useState(-1);
	const [ popupOpacity, setPopupOpacity ] = useState(0.0);
	const [ optionsElementID, _setOptionsElementID ] = useState(Helper.Hash(Math.random()));
	const [ comboID, _setComboID ] = useState(Helper.Hash(Math.random()));
	const showingPlaceholder = selectedOptionIndex < 0 || selectedOptionIndex >= props.options.length;

	function onScroll() {
		$(".combo-select.expanded").each(function() {
			const rect = this.getBoundingClientRect();
			$(this).find(".options").css({
				top: rect.bottom,
				left: rect.left,
				width: rect.width
			});
		});
	}

	useEffect(() => {
		setExpanded(0);
	}, [ selectedOptionIndex ]);

	useEffect(() => {
		if (!expanded) {
			setPopupOpacity(0);
			setMaxPopupHeight(-1);
			return;
		}

		setTimeout(() => {
			const popupElement = document.getElementById(optionsElementID);
			if (popupElement) {
				setMaxPopupHeight(Math.max(popupElement.getBoundingClientRect().bottom - window.innerHeight, window.innerHeight * 0.3));
				setPopupOpacity(1);
			}
		});

		onScroll();
	}, [ expanded ]);

	useEffect(() => {
		function close(e) {
			if ($(e.target).closest("#" + comboID).length) return;
			setExpanded(false);
		}

		$("body").on("click", close);
		$(window).on("scroll", onScroll);

		return () => {
			$("body").off("click", close);
			$(window).off("scroll", onScroll);
		};
	}, []);

	return <div id={comboID} className={"combo-select" + (props.small ? " small" : "") + (expanded ? " expanded" : "")}>
		<div className={"selected-option" + (showingPlaceholder ? " placeholder" : "")} onClick={() => {
			setExpanded(!expanded);
		}}>{!showingPlaceholder ? props.options[selectedOptionIndex].name : props.placeholder}</div>
		{expanded == true && <div className="options" id={optionsElementID} style={{ maxHeight: maxPopupHeight > 0 ? maxPopupHeight : null, opacity: popupOpacity }}>{props.options.map((option, idx) => <div className="option" key={option.value} onClick={() => {
			setSelectedOptionIndex(idx);
			if (props.onChange) props.onChange(option);
		}}>{props.renderOption ? props.renderOption(option) : option.name}</div>)}</div>}
		<MaterialIcon name={expanded ? "expand_less" : "expand_more"} className="chevron" />
	</div>;
}
