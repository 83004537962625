import { useState, useEffect } from 'react';

import Helper from '../services/Helper';
import MaterialIcon from './MaterialIcon';

export default function TimeInput(props) {
    const onChange = props.onChange;
    const defaultValue = props.defaultValue || "00:00";

    let _hours, _minutes;

    if (defaultValue.split) {
        _hours = parseInt(defaultValue.split(":")[0]);
        _minutes = parseInt(defaultValue.split(":")[1]);
    } else {
        _minutes = parseInt(defaultValue);
        _hours = 0;
        while (_minutes >= 60) {
            _hours++;
            _minutes -= 60;
        }
    }

    const [hours, setHours] = useState(_hours);
    const [minutes, setMinutes] = useState(_minutes);

    useEffect(() => {
        if (onChange) onChange(Helper.PadStart(hours, 2, "0") + ":" + Helper.PadStart(minutes, 2, "0"));
    }, [hours, minutes]);

    return <div className="time-input-container" style={props.style}>
        {props.icon ? <MaterialIcon name={props.icon} style={{ marginRight: "0.25em"}} /> : null}
        <div className="time-input">
            <input maxLength={2} pattern="[0-9]{2}" className="hours" onChange={e => setHours(e.target.value)} defaultValue={Helper.PadStart(hours, 2, "0")} />:<input maxLength={2} pattern="[0-9]{2}" className="minutes" onChange={e => setMinutes(e.target.value)} defaultValue={Helper.PadStart(minutes, 2, "0")} />
        </div>
    </div>;
}
