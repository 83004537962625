import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Events from "../services/Events";
import LoadingIndicator from "./LoadingIndicator";
import ImageUpload from "./ImageUpload";
import TextEditor from "./TextEditor";
import JSONEditor from "./JSONEditor";
import Field from "./Field";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import MaterialIcon from "./MaterialIcon";
import InputSelect from "./InputSelect";

import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Helper from "../services/Helper";
import AccordionTab from "./AccordionTab";
import { DialogContext } from "../context/DialogContext";

import "../../css/admin-editar-evento.css";

export default function AdminEditarEvento(props) {
	const [evento, setEvento] = useState(null);
	const [negocio, setNegocio] = useState(null);
	const dialogContext = useContext(DialogContext);

	const eventoID = props.eventID || 0;
	const negocioID = props.negocioID || 0;

	useEffect(() => {
		if (!negocio) return () => {};

		if (eventoID == 0) {
			setEvento({
				image_url: "",
				full_booking: 0,
				disponibilidad_multiple: 0,
				offers_upselling: 0,
				nombre: "",
				price: negocio.price,
				duration: 15,
				address: negocio.address,
				description: "",
				extras_menu: {}
			});
		} else {
			APIAdminRequest("get-event", { id: eventoID }).then((response) => {
				setEvento(response.data);
			});
		}

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, [negocio]);

	useEffect(() => {
		if (negocioID) {
			APIAdminRequest("get-negocio", { id: negocioID }).then((response) => {
				setNegocio(response.data);
			});
		} else {
			location.href = "/admin/negocios/" + negocioID;
		}
	}, []);

	const save = useCallback((notifySaved) => {
		dialogContext.openDialog("¿Seguro que quieres guardar los cambios?", "Sí", "No", confirmed => {
			if (confirmed) {
				APIAdminRequest("save-event", evento).then(() => {
					notifySaved(true);
				});
			} else {
				notifySaved(false);
			}
		});
	}, [evento]);

	if (!evento || !negocio) {
		return <LoadingIndicator />;
	}

	return (
		<div className="editar-evento edit-page">
			<AdminBar onSave={save} />
			<div className="header">
				<h2>{evento.nombre ? evento.nombre : "Nuevo evento"}</h2>
				<div
					href="#"
					className="enabled-status"
					onClick={(e) => {
						e.preventDefault();
						const newEvento = { ...evento };
						newEvento.enabled = !newEvento.enabled;
						setEvento(newEvento);
					}}>
					{evento.enabled && (
						<a href="#">
							<MaterialIcon name="toggle_on" />
						</a>
					)}
					{!evento.enabled && (
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
							}}>
							<MaterialIcon name="toggle_off" />
						</a>
					)}
				</div>
				<div className="delete-evento-container">
					<a href="#" onClick={(e) => {}}>
						<MaterialIcon name="delete" tooltip="Borrar evento" />
					</a>
				</div>
			</div>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload
						initialValue={evento.image_url ? "eventos/" + evento.image_url : ""}
						onChange={(value) => {
							const newEvento = { ...evento };
							newEvento.image_url = e.target.value.match(/eventos\/(.*)/)[1];
							setEvento(newEvento);
						}}
					/>
					<div className="toggles">
						<div>
							Full booking
							<div className={"form-toggle" + (evento.full_booking ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.full_booking = !n.full_booking;
								setEvento(n);
							}}/>
						</div>
						<div>
							Disponibilidad múltiple
							<div className={"form-toggle" + (evento.disponibilidad_multiple ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.disponibilidad_multiple = !n.disponibilidad_multiple;
								setEvento(n);
							}}/>
						</div>
						<div>
							Cross-selling
							<div className={"form-toggle" + (evento.offers_upselling ? " active" : "")} onClick={() => {
								const n = {...evento};
								n.offers_upselling = !n.offers_upselling;
								setEvento(n);
							}}/>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="columnas">
						<Field
							placeholder="Nombre"
							className="expand"
							type="string"
							defaultValue={evento.nombre}
							onChange={(value) => {
								const newEvento = { ...evento };
								newEvento.nombre = value;
								setEvento(newEvento);
							}}
						/>
						<select
							className="duracion"
							defaultValue={evento.duration}
							onChange={(e) => {
								const newEvento = { ...evento };
								newEvento.duration = e.value;
								setEvento(newEvento);
							}}>
							<option value={15}>15 min</option>
							<option value={30}>30 min</option>
							<option value={45}>45 min</option>
							<option value={60}>1 h</option>
							<option value={75}>1 h 15 min</option>
							<option value={90}>1 h 30 min</option>
							<option value={105}>1 h 45 min</option>
							<option value={120}>2 h</option>
						</select>
						<Field
							className="precio"
							type="currency"
							defaultValue={evento.price / 100}
							onChange={(value) => {
								const newEvento = { ...evento };
								newEvento.price = value * 100;
								setEvento(newEvento);
							}}
						/>
					</div>
					<Field placeholder="Dirección" className="expand" type="string" disabled={true} defaultValue={negocio.address} onChange={(value) => {}} />
					<Field
						placeholder="Descripción"
						className="descripcion expand"
						type="text"
						defaultValue={negocio.description}
						onChange={(value) => {
							const newEvento = { ...evento };
							newEvento.address = value;
							setEvento(newEvento);
						}}
					/>
				</div>
			</Bloque>
			<Bloque className="columnas">
				<AccordionTab heading="Extras" className="extras">
					<JSONEditor
						initialValue={evento.extras_menu || negocio.preorder_menu}
						onChange={(data) => {
							const newEvento = { ...evento };
							newEvento.extras_menu = data;
							setEvento(newEvento);
						}}
						associative={true}
						keyTitle="Categoría"
						properties={[{ title: "Descripción", optional: true, key: "description", type: "string" }]}
						listTitle="Productos"
						listProperties={[
							{ title: "Nombre", key: "name", type: "string" },
							{ title: "Precio", key: "price", type: "string" },
							{ title: "Descripción", key: "description", type: "text" },
							{ title: "Imagen", key: "image_url", type: "image" }
						]}
					/>
				</AccordionTab>
			</Bloque>
		</div>
	);
}
