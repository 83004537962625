export default function Bloque(props) {
    const columnCount = props.columnCount || props.children.length;
    const style = {...props.style} || {};
    style.gridTemplateColumns = "repeat(" + columnCount + ", " + columnCount + "fr)";

    return <div className={["admin-bloque", props.className].filter(v => v).join(" ")} style={style}>
        {props.children}
    </div>;
}

