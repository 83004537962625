require("../../css/admin-reservas-disponibilidad.css");

import { useEffect, useState } from 'react';

import AdminService from '../services/Admin';
import AdminBar from "./AdminBar";
import ComboSelect from "./ComboSelect";
import Session from "../services/Session";
import AdminReservas from './AdminReservas';
import AdminDisponibilidad from './AdminDisponibilidad';

export default function AdminReservasDisponibilidad(props) {
	const [ negocios, setNegocios ] = useState([]);
	const [ selectedNegocio, setSelectedNegocio ] = useState(null);
	const [ activeTab, setActiveTab ] = useState("reservas");
	const [ permissions, setPermissions ] = useState(null);

    useEffect(() => {
		AdminService.GetUserPermissions().then((permissions) => {
            setPermissions(permissions);
		});
    }, []);

	useEffect(() => {
        Session.GetAccountEvents().then(negocios => {
        	setNegocios(negocios.map(negocio => { negocio.value = negocio.id = parseInt(negocio.id); return negocio; }).sort((a, b) => {
				if (a.future_order_count > 0 && b.future_order_count == 0) {
					return -1;
				}
				
				if (a.future_order_count == 0 && b.future_order_count > 0) {
					return 1;
				}

				return a.name > b.name ? 1 : -1
			}));
		});
	}, []);

	useEffect(() => {
		setSelectedNegocio(negocios.find(negocio => negocio.future_order_count > 0) || negocios[0]);
	}, [negocios]);

	if (!permissions) return null;

	return <div className="admin-page admin-reservas">
			{<AdminBar renderElements={() => null} />}
			<div className="header">
				<div className="left">
					{permissions.data.manage_orders && <button className={"btn" + (activeTab == "reservas" ? " active" : "")} onClick={e => setActiveTab("reservas")}>Reservas</button>}
					{permissions.data.manage_availability && <button className={"btn" + (activeTab == "disponibilidad" ? " active" : "")} onClick={e => setActiveTab("disponibilidad")}>Disponibilidad</button>}
				</div>
				<div className="right">
					{negocios.length > 0 && selectedNegocio && <ComboSelect defaultValue={selectedNegocio} onChange={negocio => setSelectedNegocio(negocio)} placeholder="Selecciona un negocio" options={negocios} renderOption={option => {
						return <span className={option.future_order_count == 0 ? "no-orders" : ""}>{option.name}</span>;
					}}/>}
				</div>
			</div>
			{activeTab == "reservas" && selectedNegocio && <div className="reservas">
				<AdminReservas negocio={selectedNegocio.id} />
			</div>}
			{activeTab == "disponibilidad" && selectedNegocio && <div className="disponibilidad">
				<AdminDisponibilidad negocio={selectedNegocio.id} slug={selectedNegocio.slug} />
			</div>}
	</div>;
}
