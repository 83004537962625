import { useState, useEffect, useContext } from "react";
import ImageUpload from "./ImageUpload";
import IconSelector from "./IconSelector";
import Settings from "../services/Settings";
import LoadingIndicator from "./LoadingIndicator";

export default function Field(props) {
	const [currentValue, setCurrentValue] = useState(props.defaultValue);
	const [currencySymbolPlacement, setCurrencySymbolPlacement] = useState();
	const [currencySymbol, setCurrencySymbol] = useState();

	let defaultValue = props.defaultValue;
	const elementID = props.type + "-" + Math.floor(Math.random() * 1000000);

	if (props.type == "strings" && defaultValue) {
		defaultValue = defaultValue.join("\n");
	}

	if (props.type == "number") {
		if (typeof props.min !== "undefined" && typeof props.max !== "undefined") defaultValue = Math.max(props.min, Math.min(props.max, defaultValue));
		else if (typeof props.min !== "undefined") defaultValue = Math.max(props.min, defaultValue);
		else if (typeof props.max !== "undefined") defaultValue = Math.min(props.max, defaultValue);
	}

	useEffect(() => {
		Promise.all([Settings.Get("CURRENCY_SYMBOL_PLACEMENT", "after"), Settings.Get("CURRENCY_SYMBOL", "€")]).then(([placement, symbol]) => {
			setCurrencySymbolPlacement(placement);
			setCurrencySymbol(symbol);
		})
	}, []);

	if (!currencySymbol || !currencySymbolPlacement) return <LoadingIndicator />;

	return (
		<div id={elementID} style={props.style} className={"custom-field type-" + props.type + (props.className ? " " + props.className : "")}>
			{props.title && props.title.length && (
				<>
					<label>
						{props.title} <span>{props.optional == true && " (opcional)"}</span>
					</label>
				</>
			)}

			{props.type == "string" && (
				<input
					type="text"
					disabled={props.disabled}
					placeholder={props.placeholder}
					defaultValue={defaultValue}
					onChange={(e) => {
						setCurrentValue(e.target.value);
						props.onChange(e.target.value);
					}}
				/>
			)}

			{props.type == "currency" && (
				<>
					<input
						type="text"
						style={{
							width: "calc(75px + " + (0.70 * currencySymbol.length) + "em)",
							paddingRight: currencySymbolPlacement == "after" && (0.7 + 0.70 * currencySymbol.length + "em"),
							paddingLeft: currencySymbolPlacement == "before" &&  (0.7 + 0.70 * currencySymbol.length + "em")
						}}
						disabled={props.disabled}
						className={"currency symbol-" + currencySymbolPlacement}
						defaultValue={defaultValue}
						onChange={(e) => {
							if (e.target.value == "") e.target.value = 0;
							setCurrentValue(e.target.value);
							props.onChange(e.target.value);
						}}
					/>
					<div className={"currency-symbol placement-" + currencySymbolPlacement}>{currencySymbol}</div>
				</>
			)}

			{props.type == "number" && (
				<div className="number-field-container">
					<input
						type="text"
						disabled={props.disabled}
						style={{ textAlign: props.unitStrings ? "right" : "center" }}
						placeholder={props.placeholder}
						defaultValue={defaultValue}
						onBeforeInput={(e) => {
							if (!Number.isInteger(parseInt(e.data))) e.preventDefault();
						}}
						onChange={(e) => {
							if (e.target.value != "") {
								if (typeof props.min !== "undefined" && typeof props.max !== "undefined") e.target.value = Math.max(props.min, Math.min(props.max, e.target.value));
								else if (typeof props.min !== "undefined") e.target.value = Math.max(props.min, e.target.value);
								else if (typeof props.max !== "undefined") e.target.value = Math.min(props.max, e.target.value);
							}
							setCurrentValue(e.target.value);
							props.onChange(e.target.value);
						}}
						onBlur={(e) => {
							if (e.target.value == "") {
								e.target.value = props.min || 0;
							}
						}}
					/>
					{props.unitStrings && (currentValue == 1 ? props.unitStrings[0] : props.unitStrings[1])}
					<div
						className="minus"
						onClick={(e) => {
							const input = document.querySelector("#" + elementID + " input");
							input.value = parseInt(input.value) - 1;
							if (typeof props.min !== "undefined") input.value = Math.max(props.min, input.value);
							setCurrentValue(input.value);
							props.onChange(input.value);
						}}
						style={{ marginLeft: props.unitStrings ? "0.75em" : "0.25em" }}>
						<img src="/static/icons/minus.svg" />
					</div>
					<div
						className="plus"
						onClick={(e) => {
							const input = document.querySelector("#" + elementID + " input");
							input.value = parseInt(input.value) + 1;
							setCurrentValue(input.value);
							props.onChange(input.value);
							if (typeof props.max !== "undefined") input.value = Math.min(props.max, input.value);
						}}>
						<img src="/static/icons/plus.svg" />
					</div>
				</div>
			)}

			{props.type == "password" && (
				<input
					style={props.style}
					type="password"
					autoComplete="new-password"
					disabled={props.disabled}
					placeholder={props.placeholder}
					defaultValue={defaultValue}
					onChange={(e) => {
						setCurrentValue(e.target.value);
						props.onChange(e.target.value);
					}}
				/>
			)}

			{(props.type == "text" || props.type == "strings") && (
				<div>
					<textarea
						disabled={props.disabled}
						style={props.style}
						placeholder={props.placeholder}
						defaultValue={
							props.type == "strings"
								? defaultValue
								: defaultValue &&
								  defaultValue
										?.trim()
										.replaceAll("\n", "")
										.replaceAll(/<\/p>\s*<p>/gi, "\n\n")
										.replaceAll("<p>", "")
										.replaceAll("</p>", "")
						}
						onChange={(e) => {
							let value = e.target.value;
							if (props.type == "strings") {
								value = value.split("\n").filter((v) => v);
							} else if (props.type == "text") {
								value = value.replaceAll(/\n?([^\n]+)\n?/gi, "<p>$1</p>").trim();
							}
							setCurrentValue(value);
							props.onChange(value);
						}}
					/>
				</div>
			)}

			{props.type == "image" && (
				<ImageUpload
					disabled={props.disabled}
					initialValue={defaultValue}
					onChange={(value) => {
						setCurrentValue(e.target.value);
						props.onChange(value);
					}}
				/>
			)}

			{props.type == "icon" && (
				<IconSelector
					disabled={props.disabled}
					initialValue={defaultValue}
					onChange={(value) => {
						setCurrentValue(e.target.value);
						props.onChange(value);
					}}
				/>
			)}
		</div>
	);
}
