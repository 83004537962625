import { useEffect, useState } from "react";

export default function TextEditor(props) {
	const [elementID, setElementID] = useState();
	const [containerID, setContainerID] = useState();

	const maxLength = props.maxLength;

	useEffect(() => {
		setElementID("editor-" + Math.floor(Math.random() * 8999 + 1000));
		setContainerID("editor-container-" + Math.floor(Math.random() * 8999 + 1000));
	}, []);

	useEffect(() => {
		if (!elementID) {
			return;
		}

		$("#" + elementID).trumbowyg({
			lang: "es",
			btns: [['strong', 'em'], ['formatting'], ["link"], ["removeformat"], ['unorderedList', 'orderedList']]
		}).on("tbwchange", () => {
			const $editor = $("#" + containerID + " .trumbowyg-editor");	

			if (maxLength && $editor.text().length > maxLength) {
				$editor.html($editor[0].previousText);
				const range = document.createRange();
				const sel = window.getSelection();
				range.setStart($editor[0], 1);
				range.collapse(true);
				sel.removeAllRanges();
				sel.addRange(range);
				$editor[0].focus();
			} else {
				$editor[0].previousText = $editor.html();
			}
		});
	}, [elementID, maxLength]);

	return <div className={["text-editor-container", props.className].filter(v => v).join(" ")} id={containerID}>
		<textarea className="text-editor" id={elementID} defaultValue={props.initialValue || ""} onChange={e => {
			if (props.onChange) {
				props.onChange(e.target.value);
			}
		}} />
	</div>;
}
