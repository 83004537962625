import { useEffect, useState, useCallback, useContext } from "react";
import ImageUpload from "./ImageUpload";
import AdminService from "../services/Admin.js";
import LoadingIndicator from "./LoadingIndicator";
import Bloque from "./AdminBloque.jsx";
import Field from "./Field.jsx";
import JSONEditor from "./JSONEditor";
import AdminBar from "./AdminBar";
import ComboSelect from "./ComboSelect";
import { PermissionsContext } from "../context/PermissionsContext";
import { DialogContext } from "../context/DialogContext";

export default function AdminGeneral(props) {
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [settings, setSettings] = useState({});

	const dialogContext = useContext(DialogContext);
	const permissionsContext = useContext(PermissionsContext);

	useEffect(() => {
		setLoading(true);
		AdminService.GetAllSettings().then((settings) => {
			const newSettings = {};
			for (let setting of settings) {
				newSettings[setting.settings_key] = setting.settings_value;
			}
			setLoading(false);
			setSettings(newSettings);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	function changeValue(key, value) {
		const newSettings = { ...settings };
		newSettings[key] = value;
		setSettings(newSettings);
	}

	if (loading) return <LoadingIndicator />;

	return (
		<div className="admin-general">
			<AdminBar disabled={saving} onSave={notifySaved => {
				dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
					if (!confirmed) {
						notifySaved(false);
						return;
					}

					setSaving(true);
					AdminService.SetSettingsMultiple(settings).then(() => {
						setSaving(false);
						notifySaved(true);
					});
				});
			}} />
			<Bloque className="columnas">
				<div className="columna">
					<Field
						title="Email de contacto"
						type="string"
						defaultValue={settings["CONTACT_EMAIL"]}
						onChange={(value) => {
							changeValue("CONTACT_EMAIL", value);
						}}
					/>
					<Field
						title="Nombre de la marca"
						type="string"
						defaultValue={settings["BRAND_NAME"]}
						onChange={(value) => {
							changeValue("BRAND_NAME", value);
						}}
					/>
					<Field
						title="Símbolo de divisa"
						type="string"
						defaultValue={settings["CURRENCY_SYMBOL"] || "€"}
						onChange={(value) => {
							changeValue("CURRENCY_SYMBOL", value);
						}}
					/>
					<label>Posición del símbolo</label>
					<ComboSelect
						small={true}
						options={[
							{ name: "Antes", value: "before" },
							{ name: "Después", value: "after" }
						]}
						defaultValue={settings["CURRENCY_SYMBOL_PLACEMENT"] || "after"}
						onChange={(option) => {
							changeValue("CURRENCY_SYMBOL_PLACEMENT", option.value);
						}}
					/>
				</div>
				<div className="columna">
					<Field
						className="logo"
						title="Logo"
						type="image"
						defaultValue={settings["LOGO"]}
						onChange={(value) => {
							console.log(value);
						}}
					/>
				</div>
			</Bloque>

			<Bloque className="columnas">
				<div className="columna">
					{settings["SOCIAL_NETWORKS"] !== null && (
						<JSONEditor
							static={true}
							initialValue={settings["SOCIAL_NETWORKS"]}
							properties={[
								{ key: "name", title: "Nombre", type: "string" },
								{ key: "url", title: "URL", type: "string" }
							]}
							onChange={(value) => {
								changeValue("SOCIAL_NETWORKS", value);
							}}
						/>
					)}
				</div>
			</Bloque>

			{permissionsContext.permissions.data.edit_sensitive_configuration && (
				<>
					<Bloque className="columnas">
						<div className="columna">
							<Field
								title="Servidor SMTP"
								type="string"
								defaultValue={settings["SMTP_HOST"]}
								onChange={(value) => {
									changeValue("SMTP_HOST", value);
								}}
							/>
							<Field
								title="Puerto"
								type="string"
								defaultValue={settings["SMTP_PORT"]}
								onChange={(value) => {
									changeValue("SMTP_PORT", value);
								}}
							/>
							<Field
								title="Usuario SMTP"
								type="string"
								defaultValue={settings["SMTP_HOST"]}
								onChange={(value) => {
									changeValue("SMTP_HOST", value);
								}}
							/>
							<Field
								title="Contraseña SMTP"
								type="string"
								defaultValue={settings["SMTP_PASSWORD"]}
								onChange={(value) => {
									changeValue("SMTP_PASSWORD", value);
								}}
							/>
						</div>
					</Bloque>

					<Bloque className="columnas">
						<div className="columna">
							<Field
								title="Tag Google Analytics"
								type="string"
								defaultValue={settings["GOOGLE_ANALYTICS_TAG"]}
								onChange={(value) => {
									changeValue("GOOGLE_ANALYTICS_TAG", value);
								}}
							/>
						</div>
					</Bloque>

					<Bloque className="columnas">
						<div className="columna">
							<Field
								title="Merchant ID"
								type="string"
								defaultValue={settings["TPV_MERCHANT_ID"]}
								onChange={(value) => {
									changeValue("TPV_MERCHANT_ID", value);
								}}
							/>
							<Field
								title="Acquirer BIN"
								type="string"
								defaultValue={settings["TPV_ACQUIRER_BIN"]}
								onChange={(value) => {
									changeValue("TPV_ACQUIRER_BIN", value);
								}}
							/>
							<Field
								title="ID de terminal"
								type="string"
								defaultValue={settings["TPV_TERMINAL_ID"]}
								onChange={(value) => {
									changeValue("TPV_TERMINAL_ID", value);
								}}
							/>
							<Field
								title="URL TPV"
								type="string"
								defaultValue={settings["TPV_URL"]}
								onChange={(value) => {
									changeValue("TPV_URL", value);
								}}
							/>
							<Field
								title="Clave secreta"
								type="string"
								defaultValue={settings["TPV_SECRET"]}
								onChange={(value) => {
									changeValue("TPV_SECRET", value);
								}}
							/>
						</div>
					</Bloque>
				</>
			)}
		</div>
	);
}
