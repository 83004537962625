import { useState, useEffect, useCallback, useContext } from 'react';
import AdminService from '../services/Admin';
import LoadingIndicator from './LoadingIndicator';
import Table from './Table';
import Helper from '../services/Helper';
import FormToggle from './FormToggle';
import Field from './Field';
import AdminBar from './AdminBar';
import DoughnutChart from './estadisticas/DoughnutChart';
import Bloque from './AdminBloque';

import { DialogContext } from "../context/DialogContext";

export default function AdminUsuarios(props) {
	const [usuarios, setUsuarios] = useState();
	const [editedUser, setEditedUser] = useState();
	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		AdminService.GetUsers().then(users => {
			setUsuarios(users);
		});
	}, []);

	useEffect(() => {
		console.log(editedUser);
	}, [editedUser]);

	const save = useCallback(() => {

	}, [ editedUser ]);

	if (!usuarios) {
		return <LoadingIndicator />;
	}

	return <div className="admin-page admin-usuarios">
		<AdminBar onSave={save} />
		<div className="header">
			<h2 style={{ marginRight: "auto" }}>Listado de usuarios</h2>
		</div>
		<Table
			header={[ "Activo", "Login", "Nombre", "Apellidos", "Teléfono", "CP", "Partner", "Admin" ]}
			widths={[ 1, 3, 3, 3, 2, 1, 1, 1 ]}
			alignment={[ "center", "left", "left", "left", "left", "center", "center", "center" ]}
			items={usuarios.map(usuario => ({ data: usuario, columns: [
				<span className={"boolean-indicator" + (usuario.active ? " active" : "")}></span>,
				usuario.login,
				usuario.name,
				usuario.surname,
				Helper.FormatPhoneNumber(usuario.phone),
				usuario.postal_code,
				<span className={"boolean-indicator" + (usuario.is_partner ? " active" : "")}></span>,
				<span className={"boolean-indicator" + (usuario.is_superuser ? " active" : "")}></span>,
			]}))}
			onOpenDetails={usuario => {
				setEditedUser({id: usuario.id});
			}}
			willCloseDetails={answer => {
				dialogContext.openDialog("Los cambios no guardados se perderán. ¿Deseas continuar?", "Sí", "No", accepted => {
					answer(accepted);
				});
			}}
			renderDetails={(usuario, idx) => <>
				<div>
					<Field type="string" placeholder="Email" defaultValue={usuario.login} disabled />
					<Field type="password" placeholder="Contraseña (dejar en blanco para conservar la actual)" defaultValue="" onChange={value => {
						const n = {...editedUser};
						editedUser.newPassword = value;
						setEditedUser(n);
					}} />
					<Field type="string" placeholder="Nombre" defaultValue={usuario.name} onChange={value => {
						const n = {...editedUser};
						editedUser.name = value;
						setEditedUser(n);
					}} />
					<Field type="string" placeholder="Apellidos" defaultValue={usuario.surname} onChange={value => {
						const n = {...editedUser};
						editedUser.surname = value;
						setEditedUser(n);
					}} />
					<Field type="string" placeholder="Teléfono" defaultValue={Helper.FormatPhoneNumber(usuario.phone)} onChange={value => {
						const n = {...editedUser};
						editedUser.phone = value;
						setEditedUser(n);
					}} />
					<Field type="string" placeholder="Código postal" defaultValue={usuario.postal_code} onChange={value => {
						const n = {...editedUser};
						editedUser.postal_code = value;
						setEditedUser(n);
					}} />
				</div>
				<div className="row-guardar">
					<div>Activo <FormToggle defaultValue={usuario.active} onChange={value => {
						const n = {...editedUser};
						editedUser.active = value;
						setEditedUser(n);
					}} /></div>
					<div>Partner <FormToggle defaultValue={usuario.is_partner} onChange={value => {
						const n = {...editedUser};
						editedUser.is_partner = value;
						setEditedUser(n);
					}} /></div>
					<div>Administrador <FormToggle defaultValue={usuario.is_superuser} onChange={value => {
						const n = {...editedUser};
						editedUser.is_superuser = value;
						setEditedUser(n);
					}} /></div>
					<a className="btn btn-brown boton-guardar" onClick={e => {
						e.preventDefault();
						console.log(editedUser);
					}}>Guardar</a>
				</div>
				<div className="row-estadisticas">
					<h2>Estadísticas de usuario</h2>
					<Bloque className="columnas estadisticas">
						<div className="columna">
							<h2>Reservas por tipo de negocio</h2>
							<DoughnutChart />
						</div>
						<div className="columna">
							<h2>Indicadores de rendimiento</h2>
							<div className="kpi-container">
								<div className="item">
									<div className="value">{Helper.FormatAmount(80)}</div>
									<div className="caption">Tícket medio</div>
								</div>
								<div className="item">
									<div className="value">32</div>
									<div className="caption">Reservas</div>
								</div>
								<div className="item">
									<div className="value">33%</div>
									<div className="caption">Conversión upselling</div>
								</div>
								<div className="item">
									<div className="value">60%</div>
									<div className="caption">Conversión cross-selling</div>
								</div>
							</div>
						</div>
					</Bloque>
				</div>
			</>}
		/>
	</div >;
}
