import { useEffect, useState, useContext } from "react";
import AdminService from "../services/Admin";
import ContentHelper from "../services/ContentHelper";
import AdminBar from "./AdminBar";
import Field from "./Field";
import ImageUpload from "./ImageUpload";
import ImageUploadList from "./ImageUploadList";
import Bloque from "./AdminBloque";
import LoadingIndicator from "./LoadingIndicator";
import { DialogContext } from "../context/DialogContext";

export default function AdminPaginaEstatica(props) {
	const [strings, setStrings] = useState(null);
    const dialogContext = useContext(DialogContext);

	useEffect(() => {
		ContentHelper.GetStrings(["eventos-imagenes-banda", "eventos-imagenes-banda-inferior", "eventos-imagen-1", "eventos-imagen-2", "eventos-imagen-3", "eventos-supertitulo", "eventos-titulo", "eventos-subtitulo", "eventos-banda-titulo", "eventos-banda-texto", "eventos-destacado-1-titulo", "eventos-destacado-1-texto", "eventos-destacado-2-titulo", "eventos-destacado-2-texto", "eventos-banda-html", "eventos-banda-inferior-html", "eventos-banda-inferior-titulo", "eventos-cta"]).then((strings) => {
			setStrings(strings);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	if (!strings) return <LoadingIndicator />;

	return (
		<div className="admin-page admin-pagina-estatica">
			<AdminBar onSave={notifySaved => {
				dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
					if (!confirmed) {
						notifySaved(false);
						return;
					}

					AdminService.SaveContentMultiple(strings).then(() => {
						notifySaved(true);
					});
				});
			}} />
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload initialValue={strings["eventos-imagen-1"]} onChange={(value) => {}} />
				</div>
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-supertitulo"]} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-titulo"]} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-subtitulo"]} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-cta"]} />
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-banda-titulo"]} onChange={(value) => {}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-banda-texto"]} onChange={(value) => {}} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ImageUploadList defaultValue={JSON.parse(strings["eventos-imagenes-banda"] || "[]")} onChange={(value) => {}} />
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-destacado-1-titulo"]} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-destacado-1-texto"]} />
						</div>
					</div>
				</div>
				<div className="columna">
					<ImageUpload initialValue={strings["eventos-imagen-2"]} onChange={(value) => {}} />
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload initialValue={strings["eventos-imagen-3"]} onChange={(value) => {}} />
				</div>
				<div className="columna">
					<div className="row">
						<div className="col">
							<Field type="string" defaultValue={strings["eventos-destacado-2-titulo"]} />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field type="text" defaultValue={strings["eventos-destacado-2-texto"]} />
						</div>
					</div>
				</div>
			</Bloque>
			<Bloque className="filas">
				<div className="fila">
					<Field type="string" defaultValue={strings["eventos-banda-inferior-titulo"]} onChange={(value) => {}} />
				</div>
				<div className="fila">
					<ImageUploadList className="inferior" defaultValue={JSON.parse(strings["eventos-imagenes-banda-inferior"] || "[]")} onChange={(value) => {}} />
				</div>
			</Bloque>
		</div>
	);
}
