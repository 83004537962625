import { useState, useEffect } from "react";
import AccordionTab from "./AccordionTab";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";

export default function JSONEditor(props) {
	const [data, setData] = useState(props.initialValue || (props.associative ? {} : []));

	const onChange = props.onChange;

	useEffect(() => {
		if (onChange) onChange(data);
	}, [data]);

	if (typeof data === "string") {
		setData(JSON.parse(data));
		return null;
	}

	if (props.associative) {
		const keys = Object.keys(data);
		keys.sort((a, b) => Math.sign(data[a].order - data[b].order));

		return (
			<div className="json-editor associative">
				<div className="items">
					{!props.static && keys.length == 0 && (
						<a
							style={{ margin: "5px auto", width: 50, display: "block", textAlign: "center" }}
							href="#"
							className="plus"
							onClick={(e) => {
								e.preventDefault();
								const newData = { ...data };
								newData[""] = {
									order: 0,
									items: []
								};
								props.properties.forEach((property) => {
									newData[""][property.key] = "";
								});
								setData(newData);
							}}>
							<MaterialIcon name="add_circle" />
						</a>
					)}
					{keys.map((itemKey, idx) => {
						const group = data[itemKey];
						return (
							<div key={group.order + "-" + idx}>
								<AccordionTab
									heading={itemKey || "Sin nombre"}
									rightSideElement={
										<>
											{!props.static && (
												<a
													href="#"
													className="minus"
													onClick={(e) => {
														e.preventDefault();
														const newData = { ...data };
														delete newData[itemKey];
														setData(newData);
													}}>
													<MaterialIcon name="delete" />
												</a>
											)}
										</>
									}>
									<div className="item-key">
										<Field
											type="string"
											placeholder="Indica el nombre de la categoría"
											defaultValue={itemKey}
											onChange={(value) => {
												const newData = { ...data };
												newData[value] = data[itemKey];
												delete newData[itemKey];
												setData(newData);
											}}
										/>
									</div>
									{props.properties &&
										props.properties.map((property, idx) => {
											if (!group[property.key]) return null;

											return (
												<Field
													key={property.key}
													optional={property.optional}
													defaultValue={group[property.key]}
													type={property.type}
													title={property.title}
													onChange={(value) => {
														const newData = { ...data };
														newData[itemKey][property.key] = value;
														setData(newData);
													}}
												/>
											);
										})}
									<AccordionTab heading={props.listTitle}>
										<div className="json-list-edit">
											{group?.items &&
												group.items.map((item, idx) => {
													return (
														<div className="list-item" key={idx}>
															<AccordionTab
																heading={item[props.listProperties[0].key] || "Sin nombre"}
																rightSideElement={
																	<>
																		{!props.static && (
																			<a
																				href="#"
																				className="minus"
																				onClick={(e) => {
																					e.preventDefault();
																					const newData = { ...data };
																					newData[itemKey].items.splice(idx, 1);
																					setData(newData);
																				}}>
																				<MaterialIcon name="delete" />
																			</a>
																		)}
																	</>
																}>
																{props.listProperties.map((listProp) => {
																	return (
																		<div className="list-col" key={listProp.key}>
																			<Field
																				key={listProp.key}
																				defaultValue={item[listProp.key]}
																				type={listProp.type}
																				title={listProp.title}
																				onChange={(value) => {
																					const newData = { ...data };
																					newData[itemKey].items[idx][listProp.key] = value;
																					setData(newData);
																				}}
																			/>
																		</div>
																	);
																})}
															</AccordionTab>
														</div>
													);
												})}
											{!props.static && (
												<a
													href="#"
													className="plus plus-wide"
													onClick={(e) => {
														e.preventDefault();
														const newData = { ...data };
														const blankItem = {};
														for (let listProp of props.listProperties) {
															blankItem[listProp.key] = "";
														}
														newData[itemKey].items.push(blankItem);
														setData(newData);
													}}>
													<MaterialIcon name="add_circle" />
												</a>
											)}
										</div>
									</AccordionTab>
								</AccordionTab>
							</div>
						);
					})}
				</div>
				{!props.static && (
					<a
						href="#"
						className={"plus plus-wide" + (data[""] ? " disabled" : "")}
						onClick={(e) => {
							e.preventDefault();
							const newData = { ...data };
							newData[""] = {
								order: data[keys[keys.length - 1]].order + 1,
								items: []
							};
							props.properties.forEach((property) => {
								newData[""][property.key] = "";
							});
							setData(newData);
						}}>
						<MaterialIcon name="add_circle" />
					</a>
				)}
			</div>
		);
	}

	return (
		<div className="json-editor simple">
			<div className="json-list-edit">
				{data.map((item, idx) => {
					const heading = (props.headingPropertyKey ? item[props.headingPropertyKey] : item[props.properties[0].key]) || "Sin título";
					return (
						<AccordionTab
							key={"accordion" + idx}
							heading={heading}
							className={heading.toLowerCase().replaceAll(" ", "-")}
							rightSideElement={
								<>
									{!props.static && (
										<a
											href="#"
											className="minus"
											onClick={(e) => {
												e.preventDefault();
												const newData = [...data];
												newData.splice(idx, 1);
												setData(newData);
											}}>
											<MaterialIcon name="delete" />
										</a>
									)}
								</>
							}>
							{props.properties.map((listProp) => {
								return (
									<div className="list-col" key={listProp.key}>
										<Field
											key={listProp.key}
											defaultValue={item[listProp.key]}
											type={listProp.type}
											title={listProp.title}
											onChange={(value) => {
												const newData = [...data];
												newData[idx][listProp.key] = value;
												setData(newData);
											}}
										/>
									</div>
								);
							})}
						</AccordionTab>
					);
				})}
				{!props.static && (
					<a
						href="#"
						className="plus plus-wide"
						onClick={(e) => {
							e.preventDefault();
							const newData = [...data];
							const blankItem = {};
							for (let listProp of props.properties) {
								blankItem[listProp.key] = "";
							}
							newData.push(blankItem);
							setData(newData);
						}}>
						<MaterialIcon name="add_circle" />
					</a>
				)}
			</div>
		</div>
	);
}
