import { useState, useEffect } from "react";

export default function FormToggle(props) {
	const [value, setValue] = useState(props.defaultValue == true || false);
	const onChange = props.onChange;

	return <div onClick={e => {
		e.preventDefault();
		if (props.disabled) return;
		if (props.onChange) onChange(!value);
		setValue(!value);
	}} className={"form-toggle" + (value === true ? " active" : "") + (props.disabled === true ? " disabled" : "")} />
}
