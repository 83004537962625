import { useContext, useEffect, useRef } from "react";
import { HeaderContext } from "../services/HeaderContext";
import UI from "../services/UI";
import AsyncButton from "./AsyncButton";

export default function AdminBar(props) {
	const headerContext = useContext(HeaderContext);
	const saveButtonRef = useRef();
	const onSave = props.onSave;
	const disabled = props.disabled;

	useEffect(() => {
		headerContext.setHeaderButtons(
			<div className="admin-bar">
				{onSave && (
					<AsyncButton
						onClick={(e) => {
							if (disabled) return;
							if (onSave) {
								onSave((result) => {
									if (result === true) {
										UI.ShowNotification("Cambios guardados con éxito");
										saveButtonRef.current?.reset();
									} else {
										if (typeof result === "string") {
											// Mostrar error.
										}
									}
									saveButtonRef.current?.reset();
								});
							} else {
								saveButtonRef.current?.reset();
							}
						}}
						className={"save-button" + (disabled ? " disabled" : "")}
						onRef={(ref) => (saveButtonRef.current = ref)}
						caption="Guardar cambios"
					/>
				)}

				{props.renderElements && <div className="bar-elements">{props.renderElements()}</div>}
			</div>
		);

		return () => {
			headerContext.setHeaderButtons(null);
		}
	}, [onSave, disabled]);

	return null;
}
