import { APIAdminRequest } from "./API";

export default class AdminService {
	static async IsUserAdmin() {
		const response = await APIAdminRequest("is-admin");
		return response.status;
	}

	static async GetUsers() {
		const response = await APIAdminRequest("get-users");
		return response.data;
	}

	static async GetUser(id) {
		const response = await APIAdminRequest("get-user", { id });
		return response.data;
	}

	static async GetPartners() {
		const response = await APIAdminRequest("get-partners");
		return response.data;
	}

	static async GetExistingCities() {
		const response = await APIAdminRequest("get-existing-cities");
		return response.data;
	}

	static async GetTypes() {
		const response = await APIAdminRequest("get-types");
		return response.data;
	}

	static async GetNegocios() {
		const response = await APIAdminRequest("get-negocios");
		return response.data;
	}

	static async GetOrdersDirect(event_id, iso_date) {
		const response = await APIAdminRequest("get-orders-direct", { event_id, date: iso_date });
		return response.data;
	}

	static async GetContent(searchQuery = "") {
		const response = await APIAdminRequest("get-content", { searchQuery });
		return response.data;
	}

	static async SaveContent(slug, content, type) {
		const response = await APIAdminRequest("save-content", { slug, content, type });
		return response.status;
	}

	static async SaveContentMultiple(pairs) {
		const response = await APIAdminRequest("save-content-multiple", { pairs });
		return response.status;
	}

	static async GetSettings(key) {
		const response = await APIAdminRequest("get-settings", { key });
		return response.data;
	}

	static async GetAllSettings() {
		const response = await APIAdminRequest("get-settings-all", {});
		return response.data;
	}

	static async ChangeTypeOrder(type, offset) {
		const response = await APIAdminRequest("change-type-order", { type, offset });
		return response.status;
	}

	static async SetTypeMain(type, main) {
		const response = await APIAdminRequest("set-type-main", { type, main });
		return response.status;
	}

	static async SetSettings(key, value) {
		const response = await APIAdminRequest("set-settings", { key, value });
		return response.status;
	}

	static async SetSettingsMultiple(data) {
		const response = await APIAdminRequest("set-settings-multiple", data);
		return response.status;
	}

	static async Login(user, password) {
		const response = await APIAdminRequest("admin-auth", { user, password });
		return response.status;
	}

	static async Logout() {
		const response = await APIAdminRequest("admin-logout");
		return response.status;
	}

	static async GetAllFAQ() {
		const response = await APIAdminRequest("get-all-faq");
		return response.data;
	}

	static async SaveFAQAll(faq) {
		const array = [];
		faq.forEach((item, idx) => {
			array.push({ slug: "faq-" + (idx+1+"").padStart(3, "0") + "-titulo", content: item.titulo });
			array.push({ slug: "faq-" + (idx+1+"").padStart(3, "0") + "-contenido", content: item.contenido });
		});
		await APIAdminRequest("save-all-faq", { faq: array });
	}

	static async UpdateOrder(id, values) {
		await APIAdminRequest("update-order", { id, values });
	}

	static async CreateOrder(data) {
		const response = await APIAdminRequest("create-order", data);
		return response.status;
	}

	static async GetNegocioEvents(negocioID) {
		const result = await APIAdminRequest("get-events", { negocio_id: negocioID });
		return result.data;
	}

	static async CancelOrder(order_id) {
		const response = await APIAdminRequest("cancel-order", { id: order_id });
		return response.status;
	}

	static async DeleteAvailability(id) {
		await APIAdminRequest("delete-availability", { id });
	}

	static async CreateAvailability(event_id, dates) {
		const response = await APIAdminRequest("create-availability", { event_id, dates });
		return response.data;
	}

	static async UpdateAvailability(isoDates, data) {
		const response = await APIAdminRequest("update-availability", { dates: isoDates.join(","), data });
		return response.data;
	}

	static async GetAvailabilityForDates(isoDates, negocioID) {
		const response = await APIAdminRequest("get-availability-for-dates", { dates: isoDates.join(","), event: negocioID });
		return response.data;
	}

	static async GetAvailabilityForNegocio(negocioID) {
		const response = await APIAdminRequest("get-availability-for-negocio", { negocio_id: negocioID });

		for (let i = 0; i < response.data.negocio.events.length; i++) {
			for (let j = 0; j < response.data.negocio.events[i].availability.length; j++) {
				response.data.negocio.events[i].availability[j].endTimeEnabled = response.data.negocio.events[i].availability[j].duration != null;
			}
		}

		return response.data;
	}

	static async GetPermissions() {
		const response = await APIAdminRequest("get-permissions");
		return response.data;
	}

	static async GetUserPermissions() {
		const response = await APIAdminRequest("get-user-permissions");
		return response.data;
	}

	static async SetPermissions(permissions) {
		const response = await APIAdminRequest("set-permissions", { permissions });
		return response.data;
	}
}
