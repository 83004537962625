import { useEffect, useState } from "react";
import ContentHelper from "../services/ContentHelper.jsx";

export default function Video(props) {
	const [videoPosterFrameFilename, setVideoPosterFrameFilename] = useState();
	const [videoFilename, setVideoFilename] = useState();

	useEffect(() => {
		Promise.all([
			ContentHelper.GetString("video-home-poster-frame"),
			ContentHelper.GetString("video-home-file"),
		]).then(([posterFrame, videoFile]) => {
			setVideoPosterFrameFilename(posterFrame);
			setVideoFilename(videoFile);
		});
	}, []);

	if (!videoPosterFrameFilename || !videoFilename) return null;

	return (
		<video autoPlay={false} controls={false} loop={true} poster={"/static/images/home/" + videoPosterFrameFilename} playsInline={true}>
			<source src={"/static/images/home/" + videoFilename} type="video/mp4" />
		</video>
	);
}
