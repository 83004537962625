import Session from "./Session";

export default class Helper {
	static DATE_VERBOSE_SHORTEST = 0;
	static DATE_VERBOSE_SHORT = 1;
	static DATE_VERBOSE_LONG = 2;
	static DATE_VERBOSE_LONGEST = 3;

	static MAX_RESPONSIVE_WIDTH = 768;

	static IsResponsive() {
		return window.innerWidth <= Helper.MAX_RESPONSIVE_WIDTH;
	}

	static MinutesToTimeString(minutes) {
		if (!minutes || isNaN(parseInt(minutes))) return "";

		let hours = 0;
		minutes = parseInt(minutes);

		while (minutes >= 60) {
			minutes -= 60;
			hours++;
		}

		return hours + ":" + minutes;
	}

	static PadStart(value, length, padString) {
		while (("" + value).length < length) value = padString + "" + value;
		return value;
	}

	static Hash(string) {
		string = "" + string;
		for (var i = 0, hash = 0; i < string.length; i++) hash = (Math.imul(31, hash) + string.charCodeAt(i)) | 0;
		return hash;
	}

	static SetDocumentTitle(title) {
		if (title) {
			document.title = title + " | " + SITE_TITLE;
		} else {
			document.title = SITE_TITLE;
		}
	}

	static FormatISODate(str, verbose) {
		if (!verbose || typeof verbose === "undefined") {
			verbose = Helper.DATE_VERBOSE_SHORTEST;
		}
		let date = new Date();

		if (str) {
			const s = str.split("-");
			date = new Date(s[0], s[1] - 1, s[2]);
		}

		if (verbose === Helper.DATE_VERBOSE_SHORTEST || verbose === Helper.DATE_VERBOSE_SHORT) {
			return date.toLocaleDateString("es");
		} else if (verbose === Helper.DATE_VERBOSE_LONG) {
			return date.toLocaleDateString("es", { year: "numeric", month: "long", day: "numeric" });
		} else if (verbose === Helper.DATE_VERBOSE_LONGEST) {
			return date.toLocaleDateString("es", { weekday: "long", year: "numeric", month: "long", day: "numeric" });
		}
	}

	static AfterRender(callback) {
		setTimeout(() => {
			requestAnimationFrame(() => {
				callback(jQuery);
			});
		});
	}

	static UCFirst(str) {
		return str.substring(0, 1).toUpperCase() + str.substring(1);
	}

	static Ellipsis(str, maxLength) {
		if (str.length < maxLength) return str;
		return str.substring(0, maxLength) + "…";
	}

	static FormatDate(date, verbose) {
		return Helper.FormatISODate(Helper.GetISODate(date), verbose);
	}

	static CreateDateCompatible(ISODateString) {
		const components = ISODateString.split(" ")[0].split("-");
		return new Date(components[0], components[1] - 1, components[2]);
	}

	static GetISODate(date = new Date()) {
		const day = (date.getDate() < 10 ? "0" : "") + date.getDate();
		const month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
		const year = date.getFullYear();
		return year + "-" + month + "-" + day;
	}

	static FormatPhoneNumber(phone) {
		if (!phone) return "";
		phone = phone.replaceAll(" ", "");
		return (phone.length > 9 ? phone.substring(0, phone.length - 9) + " " : "") + phone.substring(phone.length - 9, phone.length - 6) + " " + phone.substring(phone.length - 6, phone.length - 3) + " " + phone.substring(phone.length - 3, phone.length);
	}

	static RenderDurationEndTime(startTime, duration, prefix = "") {
		if (!duration) return "";
		const d = new Date();
		d.setHours(parseInt(startTime.substring(0, 5).split(":")[0]), parseInt(startTime.substring(0, 5).split(":")[1]) + parseInt(duration));
		return prefix + ("" + d.getHours()).padStart(2, "0") + ":" + ("" + d.getMinutes()).padStart(2, "0");
	}

	static FormatDuration(minutes, longFormat) {
		minutes = parseInt(minutes);
		if (minutes < 60) {
			return minutes + (longFormat ? " minutos" : " min");
		}

		let hours = 0;
		while (minutes >= 60) {
			minutes -= 60;
			hours++;
		}

		if (minutes > 0) {
			return hours + (longFormat ? " horas " : " h ") + minutes + (longFormat ? " minutos" : " min");
		}

		return hours + (longFormat ? " horas" : " h");
	}

	static FormatAmount(amount, forceDecimals = false, addCurrencySymbol = true) {
		const split = ("" + amount).split(".");
		const bootstrapData = Session.GetBootstrapData();

		const integer = split[0];

		let fraction = 0;
		if (split.length > 1) {
			fraction = split[1].padStart(2, "0");
		}

		const integerString = "" + integer;
		let formattedInteger = "";

		let j = 0;
		for (let i = integerString.length - 1; i >= 0; i--) {
			if (j > 0 && j % 3 == 0) {
				formattedInteger = "." + formattedInteger;
			}
			formattedInteger = integerString[i] + formattedInteger;

			j++;
		}

		const placement = bootstrapData.currency?.placement || "after";
		const symbol = bootstrapData.currency?.symbol || "€";

		if (fraction > 0 || forceDecimals) {
			const fractionString = ("" + fraction).padEnd(2, "0");
			if (addCurrencySymbol) {
				if (placement == "before") {
					return symbol + formattedInteger + "," + fractionString;
				} else if (placement == "after") {
					return formattedInteger + "," + fractionString + " " + symbol;
				}

				return formattedInteger + "," + fractionString;
			} else {
				return formattedInteger + "," + fractionString;
			}
		} else {
			if (addCurrencySymbol) {
				if (placement == "before") {
					return symbol + formattedInteger;
				} else if (placement == "after") {
					return formattedInteger + " " + symbol;
				}

				return formattedInteger;
			} else {
				return formattedInteger;
			}
		}
	}

	static GenTimeOptions() {
		const d = new Date();
		const output = [];
		d.setHours(0, 0);
		d.setDate(1);
		while (d.getDate() < 2) {
			output.push(("" + d.getHours()).padStart(2, "0") + ":" + ("" + d.getMinutes()).padStart(2, "0"));
			d.setMinutes(d.getMinutes() + 15);
		}
		return output;
	}

	static ValidateEmail(value) {
		// eslint-disable-next-line no-control-regex
		return value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
	}

	static SngNormalize(str) {
		return str.toLowerCase().replaceAll(" ", "-").replaceAll("à", "a").replaceAll("á", "a").replaceAll("è", "e").replaceAll("é", "e").replaceAll("í", "i").replaceAll("ò", "o").replaceAll("ó", "o").replaceAll("ú", "u").replaceAll(/--+/gi, "-");
	}

	static StringCompare(str1, str2) {
		const str1_normalized = Helper.StringNormalize(str1);
		const str2_normalized = Helper.StringNormalize(str2);
		return str1_normalized === str2_normalized;
	}

	static RenderFullTimeSlot(startTime, meanLunchTime, meanDiningTime, event) {
		let hours = parseInt(startTime.split(":")[0]);
		let minutes = parseInt(startTime.split(":")[1]);

		let hoursStr;
		let minutesStr;

		if (minutes < 10) minutesStr = "0" + minutes;
		else minutesStr = "" + minutes;
		if (hours < 10) hoursStr = "0" + hours;
		else hoursStr = "" + hours;
		startTime = hours + ":" + minutes;

		if (meanLunchTime == 0 && meanDiningTime == 0) {
			return startTime;
		}

		minutes = parseInt(minutesStr);
		hours = parseInt(hoursStr);

		if (typeof event !== "undefined" && event.uses_limited_slots == 0) {
			return startTime;
		} else {
			if (hours >= 16) {
				minutes += parseInt(meanDiningTime);
			} else {
				minutes += parseInt(meanLunchTime);
			}

			while (minutes >= 60) {
				minutes -= 60;
				hours++;
			}

			if (minutes < 10) minutesStr = "0" + minutes;
			else minutesStr = "" + minutes;

			return startTime + " a " + hours + ":" + minutes;
		}
	}

	static RenderPriceRating(rating) {
		let output = "";
		for (let i = 0; i < rating; ++i) {
			output += "€";
		}
		return output;
	}

	static RenderTags(tags) {
		if (!tags) return "";
		return tags.join ? tags.join(" · ").toUpperCase() : JSON.parse(tags).join(" · ").toUpperCase();
	}

	static GetCityShortCode(city) {
		switch (city) {
			default:
				return city;
			case "Madrid":
				return "MAD";
			case "València":
				return "VLC";
		}
	}

	static SummarizeText(text, maxLength) {
		if (text.length > maxLength) {
			text = text.substr(0, maxLength - 1) + "…";
		}
		return text;
	}

	static FilterObject(obj, callback) {
		const array = Object.entries(obj);
		const filtered = array.filter(([k, v]) => callback(v, k));
		return Object.fromEntries(filtered);
	}
}
