import { APIRequest } from "./API";

export default class Settings {
	static async GetAll() {
		const response = await APIRequest("settings/get-all");
		return response.data;
	}

	static async Get(key, defaultValue = null) {
		const response = await APIRequest("settings/get", { key });
		return response.data || defaultValue;
	}
}
