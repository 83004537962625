import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Events from '../services/Events';
import Session from '../services/Session';
import $ from 'jquery';

export default class ReservaCompletada extends Component {
    constructor(props) {
        super(props);

        this.state = {
            restaurant: JSON.parse(Session.GetBookingData("selected_restaurant") || "null"),
            favorited: false,
            alreadyFavorite: true
        };

        if (this.state.restaurant) Events.IsFavorite(this.state.restaurant.id).then(alreadyFavorite => {
            this.setState({ alreadyFavorite });
        });

        let user_data = sessionStorage.getItem('user_data');
        if(user_data) {
            this.state.user_data = JSON.parse(user_data);
        }
    }

    componentDidMount() {
        $('header').addClass('simple');
        Session.ClearBookingData();
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    render() {
        return (
            <div className="reserva-completada">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Tu reserva se ha completado con éxito</h1>
                            {this.state.user_data && <h2>En breve recibirás un correo de confirmación en <span className="user-email">{this.state.user_data.login}</span>.<br/>Recuerda comprobar también la carpeta de spam.</h2>}

                            <div className="buttons">
                                {this.state.restaurant && !this.state.alreadyFavorite && <div className="add-favorite" onClick={e => {
                                    this.setState({ favorited: !this.state.favorited });
                                    Events.SetFavorite(this.state.restaurant.id, !this.state.favorited);
                                }}><div className="heart-container"><i className={this.state.favorited ? "fas fa-heart" : "far fa-heart"} /></div>Añadir {this.state.restaurant.name} a mis favoritos</div>}
                                <Link to="/" className="btn btn-brown">Volver al inicio</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
