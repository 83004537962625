import Helper from "./Helper";
import Icon from "../components/Icon";
import CarouselEventos from "../components/CarouselEventos";
import AdminService from "./Admin";
import MaterialIcon from "../components/MaterialIcon";
import Field from "../components/Field";

export default class SearchResultsProvider {
	_results = [];
	_elements = [];
	_searchParameters = { ciudad: "", fecha: "", pax: "" };
	_currentMainType = "";
	_currentOtherType = "";
	_mainTypeOffset = 0;
	_adminMode = false;
	_adminRefreshCallback = null;
	_lastTitleRendered = "";
	_onChangeTypeInfo = null;

	constructor(elementList, elementsPerRow = 5) {
		this.elementsPerRow = elementsPerRow;
		this.setElementList(elementList);
	}

	setOnChangeTypeInfo(cb) {
		this._onChangeTypeInfo = cb;
	}

	setAdminRefreshCallback(callback) {
		this._adminRefreshCallback = callback;
	}

	setAdminMode(adminMode) {
		this._adminMode = adminMode;
	}

	setElementList(elementList) {
		this._elements = [...elementList];
		this.search(this._searchParameters.ciudad, this._searchParameters.fecha, this._searchParameters.pax);
	}

	resetTitleRendering() {
		this._lastTitleRendered = "";
		return true;
	}

	search(ciudad = "", fecha = "", pax = 1) {
		this._results = this.getFilteredElements(ciudad, fecha, pax);
		this._searchParameters = { ciudad, fecha, pax };
		this.resetRenderingOffsets();

		return this._results;
	}

	getFilteredElements(ciudad, fecha, pax) {
		let results = [...this._elements];

		if (ciudad != "") {
			results = results.filter((negocio) => negocio.city == ciudad);
		}

		if (fecha != "") {
			results = results.filter((negocio) => negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => row.slot.indexOf(Helper.GetISODate(fecha)) != -1).length > 0);
		}

		const effectivePax = Math.max(1, pax);

		results = results.filter((negocio) => negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => row.pax >= effectivePax).length > 0);

		return results;
	}

	resetRenderingOffsets() {
		if (this._results.length == 0 || typeof this._results[0].tipo != "object") {
			return;
		}

		this._mainTypeOffset = 0;
		this._currentMainType = this._results.filter((result) => parseInt(result.tipo.main) == 1)[0]?.tipo?.type;
		this._currentOtherType = this._results.filter((result) => parseInt(result.tipo.main) != 1)[0]?.tipo?.type;
	}

	_getMainResultsForRendering(maxCount, consumeResults = false) {
		if (!this._currentMainType) {
			return [];
		}

		const currentTypeResults = this._results.filter((result) => parseInt(result.tipo.main) == 1 && result.tipo.type == this._currentMainType);
		const results = currentTypeResults.slice(this._mainTypeOffset, this._mainTypeOffset + maxCount);

		if (consumeResults) {
			this._mainTypeOffset += maxCount;

			if (this._mainTypeOffset >= currentTypeResults.length) {
				const mainTypesList = this._results.map((result) => (parseInt(result.tipo.main) == 1 ? result.tipo.type : null)).filter((v, i, a) => v && a.indexOf(v) == i);
				const currentIndex = mainTypesList.indexOf(this._currentMainType);

				if (currentIndex >= 0 && currentIndex < mainTypesList.length) {
					this._currentMainType = mainTypesList[currentIndex + 1];
					this._mainTypeOffset = 0;
				} else {
					this._currentMainType = null;
				}
			}
		}

		return results;
	}

	moveType(type, offset) {
		if (this._onChangeTypeInfo) {
			const newType = { ...type };
			newType.previousOrder = newType.order;
			newType.order += offset;
			this._onChangeTypeInfo(newType);
		}
	}

	setTypeMain(type, main) {
		if (this._onChangeTypeInfo) {
			const newType = { ...type };
			newType.main = main;
			this._onChangeTypeInfo(newType);
		}
	}

	renderMainRow(maxCount = 10000) {
		const results = this._getMainResultsForRendering(maxCount, true);
		if (results.length == 0) {
			return null;
		}

		const currentTipo = results[0].tipo;
		const lastTitleRendered = this._lastTitleRendered;
		this._lastTitleRendered = currentTipo.plural;

		return (
			<div className="container">
				<div className="row">
					<div className="col">
						{!this._adminMode && lastTitleRendered != currentTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(currentTipo.plural)}</h2>}
						{this._adminMode && (
							<>
								<Field
									type="string"
									onChange={(value) => {
										if (this._onChangeTypeInfo) {
											const newCurrentTipo = { ...currentTipo };
											newCurrentTipo.previousType = newCurrentTipo.type;
											newCurrentTipo.type = value;
											this._onChangeTypeInfo(newCurrentTipo);
										}
									}}
									title="Singular"
									defaultValue={currentTipo.type}
								/>
								<Field
									type="string"
									onChange={(value) => {
										if (this._onChangeTypeInfo) {
											const newCurrentTipo = { ...currentTipo };
											newCurrentTipo.plural = value;
											this._onChangeTypeInfo(newCurrentTipo);
										}
									}}
									title="Plural"
									defaultValue={currentTipo.plural}
								/>
								<Field
									type="text"
									onChange={(value) => {
										if (this._onChangeTypeInfo) {
											const newCurrentTipo = { ...currentTipo };
											newCurrentTipo.description = value;
											this._onChangeTypeInfo(newCurrentTipo);
										}
									}}
									title="Descripción"
									defaultValue={currentTipo.description}
								/>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.setTypeMain(currentTipo, 0);
									}}>
									<MaterialIcon name="star" />
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.moveType(currentTipo, -1);
									}}>
									<MaterialIcon name="arrow_upward" />
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.moveType(currentTipo, 1);
									}}>
									<MaterialIcon name="arrow_downward" />
								</a>
							</>
						)}
						<div className="bodegas-container">
							<div className="carrusel-main-eventos" style={{ gridTemplateColumns: "repeat(" + this.elementsPerRow + ", " + this.elementsPerRow + "fr)", width: Helper.IsResponsive() ? "calc(" + results.length * 100 + "vw - " + results.length * 100 + "px)" : "auto" }}>
								{results.map((negocio, idx) => {
									let link;
									if (this._adminMode) {
										link = "/admin/negocios/" + negocio.id;
									} else {
										link = "/venue/" + negocio.slug;
									}

									let minimumPrice = -1;

									negocio.events.forEach((event) => {
										event.availability.forEach((row) => {
											if (row.price == -1) {
												if (event.price == -1) {
													if (minimumPrice == -1 || (negocio.price != -1 && negocio.price < minimumPrice)) {
														minimumPrice = negocio.price;
													}
												} else {
													if (minimumPrice == -1 || (event.price != -1 && event.price < minimumPrice)) {
														minimumPrice = event.price;
													}
												}
											} else {
												if (minimumPrice == -1 || (row.price != -1 && row.price < minimumPrice)) {
													minimumPrice = row.price;
												}
											}
										});
									});

									if (minimumPrice == -1) {
										return null;
									}

									return (
										<div className="carrusel-main-evento" key={idx} onClick={(e) => (location.href = link)}>
											<div className="image-container" style={{ height: 200 + (5 - this.elementsPerRow) * 50, backgroundImage: "url(/static/images/eventos/" + negocio.image_url + ")" }}>
												{negocio.iconos && (
													<div className="icons">
														{negocio.iconos.map((icono, idx) => {
															return (
																<div
																	style={{ position: "relative" }}
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		return false;
																	}}
																	key={idx}>
																	<Icon name={icono.name} pixelSize={32} />
																	<div className="icon-tooltip">{icono.description}</div>
																</div>
															);
														})}
													</div>
												)}
											</div>
											<div className="details">
												<div className="name">{negocio.name}</div>
												<div className="city">{negocio.city}</div>
												{!this._adminMode && (
													<div className="minimum-price">
														<div>
															Desde <span>{Helper.FormatAmount(minimumPrice / 100)}</span>
														</div>
													</div>
												)}
											</div>
										</div>
									);
								})}
								{this._adminMode && (
									<div className="carrusel-main-evento" onClick={(e) => (location.href = "/admin/negocios/0")}>
										<div className="image-container placeholder"></div>
										<div className="details">
											<div className="name placeholder"></div>
											<div className="city placeholder"></div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_getOtherResultsForRendering(consumeResults = false) {
		if (!this._currentOtherType) {
			return [];
		}

		const results = this._results.filter((result) => parseInt(result.tipo.main) != 1 && result.tipo.type == this._currentOtherType);

		if (consumeResults) {
			const otherTypesList = this._results.map((result) => (parseInt(result.tipo.main) != 1 ? result.tipo.type : null)).filter((v, i, a) => v && i == a.indexOf(v));
			const currentIndex = otherTypesList.indexOf(this._currentOtherType);

			if (currentIndex >= 0 && currentIndex < otherTypesList.length) {
				this._currentOtherType = otherTypesList[currentIndex + 1];
			} else {
				this._currentOtherType = null;
			}
		}

		return results;
	}

	renderOtherRow() {
		const results = this._getOtherResultsForRendering(true);

		if (results.length == 0) {
			return null;
		}

		const otherTipo = results[0].tipo;
		const lastTitleRendered = this._lastTitleRendered;
		this._lastTitleRendered = otherTipo.plural;

		return (
			<div className="banda-otros">
				<div className="container">
					<div className="info">
						{!this._adminMode && lastTitleRendered != otherTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(otherTipo.plural)}</h2>}
						{this._adminMode && (
							<>
								<Field
									type="string"
									onChange={(value) => {
										const newOtherTipo = { ...otherTipo };
										newOtherTipo.previousType = newOtherTipo.type;
										newOtherTipo.type = value;
										if (this._onChangeTypeInfo) {
											this._onChangeTypeInfo(newOtherTipo);
										}
									}}
									title="Singular"
									defaultValue={otherTipo.type}
								/>

								<Field
									type="string"
									onChange={(value) => {
										const newOtherTipo = { ...otherTipo };
										newOtherTipo.plural = value;
										if (this._onChangeTypeInfo) {
											this._onChangeTypeInfo(newOtherTipo);
										}
									}}
									title="Plural"
									defaultValue={otherTipo.plural}
								/>

								<Field
									type="text"
									onChange={(value) => {
										const newOtherTipo = { ...otherTipo };
										newOtherTipo.description = value;
										if (this._onChangeTypeInfo) {
											this._onChangeTypeInfo(newOtherTipo);
										}
									}}
									title="Descripción"
									defaultValue={otherTipo.description}
								/>

								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.setTypeMain(otherTipo, 1);
									}}>
									<MaterialIcon name="grade" />
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.moveType(otherTipo, -1);
									}}>
									<MaterialIcon name="arrow_upward" />
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										this.moveType(otherTipo, 1);
									}}>
									<MaterialIcon name="arrow_downward" />
								</a>
							</>
						)}
						{!this._adminMode && <div>{otherTipo.description}</div>}
					</div>
					<CarouselEventos adminMode={this._adminMode} elements={results} />
				</div>
			</div>
		);
	}

	renderDefaultRows(renderMainRows = true, renderOtherRows = true, limit = -1) {
		if (limit == 0) {
			return null;
		}

		return (
			<>
				{renderMainRows && this.renderMainRow(this.elementsPerRow)}
				{renderOtherRows && this.renderOtherRow()}
				{limit != 0 && ((renderMainRows && this._getMainResultsForRendering(this.elementsPerRow).length) || (renderOtherRows && this._getOtherResultsForRendering().length)) > 0 && this.renderDefaultRows(renderMainRows, renderOtherRows, limit - 1)}
			</>
		);
	}

	getResults() {
		return this._results;
	}
}
