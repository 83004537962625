import { useState, useEffect, useCallback, useContext } from "react";

import Bloque from "./AdminBloque";
import Helper from "../services/Helper";
import DoughnutChart from "./estadisticas/DoughnutChart";
import BarChart from "./estadisticas/BarChart";
import HorizontalBarChart from "./estadisticas/HorizontalBarChart";

export default function AdminEstadisticas(props) {
	useEffect(() => {
		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const countries = [
		{ name: "Estados Unidos", orderCount: 1000 },
		{ name: "Canada", orderCount: 900 },
		{ name: "Francia", orderCount: 800 },
		{ name: "Argentina", orderCount: 700 },
		{ name: "Alemania", orderCount: 600 },
		{ name: "Reino Unido", orderCount: 500 },
		{ name: "España", orderCount: 400 },
		{ name: "República Dominicana", orderCount: 300 },
		{ name: "Chile", orderCount: 200 }
	];

	return (
		<div className="admin-page admin-estadisticas">
			<Bloque className="columnas">
				<div className="columna grafica">
					<h2>Reservas por tipo de negocio</h2>
                    <DoughnutChart />
				</div>

				<div className="columna">
					<h2>Reservas por día de la semana</h2>
                    <BarChart />
				</div>
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<h2>Indicadores de rendimiento</h2>
					<div className="kpi-container">
						<div className="item">
							<div className="value">{Helper.FormatAmount(85)}</div>
							<div className="caption">Tícket medio por persona</div>
						</div>
						<div className="item">
							<div className="value">71%</div>
							<div className="caption">Recurrencia</div>
						</div>
						<div className="item">
							<div className="value">67%</div>
							<div className="caption">Conversión upselling</div>
						</div>
						<div className="item">
							<div className="value">53%</div>
							<div className="caption">Conversión cross-selling</div>
						</div>
					</div>
				</div>

				<div className="columna grafica">
					<h2>Reservas por país</h2>
					<div className="country-list">
						{[...countries].sort((a, b) => a.name < b.name ? -1 : (b.name < a.name ? 1 : 0)).map(country => {
							return <div className="country" key={country.name}>
								<div className="country-name">{country.name}</div>
								<div style={{ width: Math.floor(100 * country.orderCount / countries.sort((a, b) => a.orderCount > b.orderCount ? -1 : (b.orderCount > a.orderCount ? 1 : 0))[0].orderCount) + "%" }}><HorizontalBarChart /></div>
							</div>;
						})}
					</div>
				</div>
			</Bloque>
		</div>
	);
}
