import { useEffect, useState, useCallback } from "react";
import AdminService from "../services/Admin.js";
import LoadingIndicator from "./LoadingIndicator";
import AdminBar from "./AdminBar";
import FormToggle from "./FormToggle.jsx";
import Table from "./Table.jsx";
import { APIAdminRequest } from "../services/API.js";

export default function AdminPermisos(props) {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [modifiedUser, setModifiedUser] = useState({});

    useEffect(() => {
		setLoading(true);
		AdminService.GetPermissions().then((permissions) => {
            setPermissions(permissions);
            setLoading(false);
		});
    }, []);

    if (loading) return <LoadingIndicator />;

    return (
	<div className="admin-page admin-permisos">
        <AdminBar />
        <Table
			header={[ "Nombre", "Email", "" ]}
			widths={[ 2, 2, 6 ]}
			alignment={[ "left", "left" ]}
			items={permissions.map(usuario => ({ data: usuario, columns: [
                usuario.name,
                usuario.login
            ]}))}
            onOpenDetails={row => {
                setModifiedUser({...row});
            }}
			renderDetails={(row, idx) => <>
				<div>
                    {Object.keys(row.data).map(permission_key => <div key={permission_key}>
                        <span>{row.strings[permission_key]}</span> <FormToggle type="checkbox" defaultValue={row.data[permission_key]} onChange={value => {
                            const newPermissions = [...permissions];
                            newPermissions[idx].data[permission_key] = value;
                            setPermissions(newPermissions);
                        }} />
                    </div>)}
				</div>
				<div>
					<a className={"btn btn-brown boton-guardar" + (saving ? " disabled" : "")} onClick={e => {
						e.preventDefault();

                        if (saving) return;
                        setSaving(true);
                        
                        APIAdminRequest("set-user-permissions", { user_id: modifiedUser.user_id, data: modifiedUser.data }).then(response => {
                            setSaving(false);
                        });
					}}>Guardar</a>
				</div>
			</>}
		/>
	</div>
    );
}
