import FormInput from "./FormInput.jsx";

export default function EventInfo(props) {
    let info;
    
    if (props.negocio) {
        info = props.negocio.info;
    } else if (props.info) {
        info = JSON.parse(props.info);
    }

    if (props.editable) {
        return <div className="event-text event-info">
            <div><img className="icon-svg hover-image" src="/static/icons/calendar.svg" style={{ width: 20, height: 20 }} /> <FormInput initialValue={info.dias} /></div>
            <div><img className="icon-svg hover-image" src="/static/icons/clock.svg" style={{ width: 20, height: 20 }} /> <FormInput initialValue={info.horario} /></div>
        </div>;
    } else {
        return <div className="event-text event-info">
            <div><img className="icon-svg hover-image" src="/static/icons/calendar.svg" style={{ width: 20, height: 20 }} /> {info.dias}</div>
            <div><img className="icon-svg hover-image" src="/static/icons/clock.svg" style={{ width: 20, height: 20 }} /> {info.horario}</div>
        </div>;
    }
}