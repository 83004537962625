import React, { useEffect, useState } from 'react';
import { APIRequest, APIRequestCached } from '../services/API';
import HTMLReactParser from 'html-react-parser';

export async function GetJSON(slug) {
    const response = await APIRequest("cms/get", { slug });
    return JSON.parse(response.data.content);
}

export function Content({slug, onEmpty, onLoad, containerClassName}) {
    const [empty, setEmpty] = useState(true);
    const [contents, setContents] = useState(null);

    useEffect(() =>{
        APIRequestCached('cms/get', { slug: slug }).then(response => {
            if(!response.data.content) {
                if (!empty) {
                    setEmpty(true);
                }

                if (onEmpty) {
                    onEmpty();
                }

            } else {
                if (onLoad) {
                    onLoad(response.data.content);
                }
                setEmpty(false);
                setContents(HTMLReactParser(response.data.content));
            }
        });
    }, [slug, empty, onEmpty, onLoad]);

    if (empty) {
        return null;
    }

    if (containerClassName) {
        return <div className={containerClassName}>{contents}</div>;
    }

    if (contents) {
        return [contents];
    }

    return (
        <span id={"cms-contents-" + slug} class="cms-content-placeholder"></span>
    );
}

export function ContentImage({alt, slug, className, containerClassName, onLoad}) {
    const [empty, setEmpty] = useState(true);
    const [src, setSrc] = useState(null);

    useEffect(() => {
        APIRequestCached('cms/get', { slug: slug }).then(response => {
            if (response.data.content) {
                if (empty) {
                    setEmpty(false);
                }

                if (onLoad) {
                    onLoad(response.data.content);
                }

                setSrc(response.data.content);
            } else if(!empty) {
                setEmpty(true);
            }
        });
    }, [slug, empty, onLoad]);

    if (empty) {
        return null;
    }

    if (containerClassName) {
        return (
            <div className={containerClassName}>
            <img alt={alt} className={className} src={"/static/images/" + src} />
            </div>
        );
    }

    return (
        <img alt={alt} className={className} src={"/static/images/" + src} />
    );
}
