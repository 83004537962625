import { useState } from "react";
import MaterialIcon from "./MaterialIcon";

export default function AccordionTab(props) {
	const [expanded, setExpanded] = useState();

	return (
		<div className={"accordion-tab-container" + (props.className ? (" " + props.className + "-container") : "")}>
			<div className={"accordion-tab" + (expanded ? " active" : "") + (props.className ? " " + props.className : "")}>
				<div className="heading" onClick={(e) => setExpanded(!expanded)}>
					{props.heading}
					<MaterialIcon name={expanded ? "expand_less" : "expand_more"} />
				</div>
				{expanded && <div className="contents">{props.children}</div>}
			</div>
			{props.rightSideElement ? <div className="right-side">{props.rightSideElement}</div> : null}
		</div>
	);
}
