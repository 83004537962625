import React from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import { APIRequest } from "../services/API";
import HTMLReactParser from 'html-react-parser';

async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export default class TPVWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formHTML: null
        };

        APIRequest("payment/get-tpv-form", {
            operacion: this.props.order + "-" + Date.now(),
            amount: this.props.amount,
            currency: "978"
        }).then(response => {
            this.setState({ formHTML: response.data });
        });
    }

    render() {

        if (!this.state.formHTML || !this.state.formHTML.length) {
            return <LoadingIndicator />;
        }

        // Tarjetas de pruebas
        // 5540500001000004 CVV2 989 12/21
        // 5020470001370055 CVV2 989 12/21
        // 5020080001000006 CVV2 989 12/21
        // 4507670001000009 CVV2 989 12/21

        return HTMLReactParser(this.state.formHTML);
    }
}
