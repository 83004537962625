import React from "react";
import Icon from "./Icon";
import $ from 'jquery';

export default class AsyncButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inProgress: false,
            confirmationDelay: 0,
            uniqueID: "" + Math.floor(Math.random() * 10000)
        };

        this.$button = null;
    }

    componentDidMount() {
        this.$button = $("#" + this.state.uniqueID);

        this.$button.css({
            width: this.$button.outerWidth(),
            height: this.$button.outerHeight()
        });

        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    render() {
        const disabled = this.state.inProgress || this.props.disabled;
        return (
            <button style={this.props.style} id={this.state.uniqueID} className={[this.props.className, "async-button", 'btn', 'btn-brown', this.state.confirmationDelay > 0 ? "confirmation" : null, disabled ? 'disabled' : null].join(" ")} onClick={() => {
                if (disabled) return;

                this.setState({ inProgress: true });
                if(this.props.onClick) this.props.onClick(this);
            }}>{this.state.inProgress ? this.renderProgressIndicator() : (this.props.confirm && this.state.confirmationDelay > 0 ? this.renderConfirmationIndicator() : this.props.caption)}</button>
        );
    }

    reset(success = true) {
        this.setState({
            inProgress: false,
            confirmationDelay: success ? 1000 : 0
        });

        setTimeout(() => {
            this.setState({ confirmationDelay: 0 });
        }, 1000);
    }

    renderConfirmationIndicator() {
        return (
            <Icon name="tick-verde" size="2" />
        );
    }

    renderProgressIndicator() {
        return (
            <span className="progress-dots"><span className="progress-dot-1" /><span className="progress-dot-2" /><span className="progress-dot-3" /></span>
        );
    }
}