import React from 'react';

export default class TabMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTabIndex: 0
        };
    }

    render() {
        if (!this.props.tabs || this.state.currentTabIndex >= this.props.tabs.length) {
            return null;
        }

        return (
            <div className={"tab-menu" + (this.props.className ? " " + this.props.className : "")}>
                <div className="links">
                    {this.props.customElementsLeft && <div className="custom-elements">{this.props.customElementsLeft()}</div>}
                    {this.props.tabs.map((tab, idx) => {
                        return (
                            <a href="#" key={idx} className={this.state.currentTabIndex === idx ? "active" : ""} onClick={e => {
                                e.preventDefault();

                                if (this.props.onChange) {
                                    this.props.onChange(tab);
                                }
                                
                                if (tab.onClick) {
                                    tab.onClick();
                                } else {
                                    this.onClickLink.call(this, idx);
                                }
                        }}>{tab.caption}</a>
                        );
                    })}
                </div>
                <div className="content-area">
                    {this.props.tabs[this.state.currentTabIndex].render()}
                </div>
            </div>
        );
    }

    onClickLink(index) {
        this.setState({ currentTabIndex: index });

        if (this.props.tabs[index].onEnter) {
            setTimeout(this.props.tabs[index].onEnter, 1);
        }
    }
};