import react, { useState, useRef } from "react";
import Icon from "./Icon";
import Modal from "./Modal";
import Calendar from "./Calendar";
import Helper from "../services/Helper";

export default function WidgetFiltro(props) {
    const [ciudad, setCiudad] = useState("");
    const [fecha, setFecha] = useState("");
    const [pax, setPax] = useState(-1);
    const [filteredElements, setFilteredElements] = useState(props.elements);

    const ciudadesModalRef = useRef();
    const fechasModalRef = useRef();
	const resultsProvider = props.resultsProvider;

    function changeFilter(ciudad, fecha, pax) {
        const results = resultsProvider.search(ciudad, fecha, pax);

        if (props.onFiltered) {
            props.onFiltered(results, ciudad != "" || fecha != "" || pax > 1);
        }

        setCiudad(ciudad);
        setFecha(fecha);
        setPax(pax);
        setFilteredElements(results);
    }

    const shownPax = Math.max(1, pax);

    return <>
        <div className="widget-filtro">
            <a href="#" onClick={e => {
                e.preventDefault();
                ciudadesModalRef.current.open();
            }} className={["filtro-ciudades", ciudad != "" && "active"].join(" ")}>{ciudad != "" ? Helper.Ellipsis(Helper.UCFirst(ciudad), 18) : "Lugar"}{ciudad != "" && <div className="reset-filter" onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                changeFilter("", fecha, pax);
            }}>×</div>}</a>
            <a href="#" onClick={e => {
                e.preventDefault();
                fechasModalRef.current.open();
            }} className={["filtro-fecha", fecha != "" && "active"].join(" ")}>{fecha != "" ? Helper.FormatDate(fecha, Helper.DATE_VERBOSE_SHORT) : (Helper.IsResponsive() ? "Más filtros" : "Fecha")}{fecha != "" && <div className="reset-filter" onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                changeFilter(ciudad, "", pax);
            }}>×</div>}</a>
            {!Helper.IsResponsive() && <a href="#" style={{ cursor: "default" }} onClick={e => {
                e.preventDefault();
            }} className="filtro-pax">
                <span>{shownPax} persona{shownPax != 1 && "s"}</span>
                <span className="boton-cantidad"><div onClick={e => {
                    changeFilter(ciudad, fecha, Math.max(1, shownPax - 1));
                }}><Icon name="minus" pixelSize={18} /></div></span>
                <span className="boton-cantidad"><div onClick={e => {
                    changeFilter(ciudad, fecha, shownPax + 1);
                }}><Icon name="plus" pixelSize={18} /></div></span>
            </a>}
        </div>

        <Modal
            ref={ref => ciudadesModalRef.current = ref}
            title="Selecciona un lugar"
            className="search-city-modal"
            onCancel={() => {
                changeFilter("", fecha, pax);
            }}
            renderContent={_modal => {
                return props.ciudades.map((ciudad, idx) => {
                    let disabled = false;

                    if (filteredElements.filter(negocio => {
                        if (negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter(a => a.city_alt == ciudad).length) {
                            return true;
                        }

                        return negocio.city == ciudad;
                    }).length == 0) {
                        disabled = true;
                    }

                    return <div className={["city-button", disabled ? "disabled" : ""].join(" ")}
                        key={idx}
                        onClick={e => {
                            if (disabled) {
                                return;
                            }

                            changeFilter(ciudad, fecha, pax);
                            ciudadesModalRef.current.close();
                        }}
                        style={{
                            backgroundImage: 'url("/static/images/ciudades/' + Helper.SngNormalize(ciudad) + '.png")'
                        }}>
                        <div className="city-name-tag">{ciudad}</div>
                    </div>
                });
            }} />

        <Modal
            ref={ref => fechasModalRef.current = ref}
            title="Selecciona una fecha"
            className="calendar-modal"
            onCancel={() => {
                changeFilter(ciudad, "", pax);
            }}
            renderContent={_modal => {
                let combinedCalendar = {};

                resultsProvider.getFilteredElements(ciudad, "", pax).forEach(venue => {
                    Object.keys(venue.calendar).forEach(date => {
                        if (ciudad != "") {
                            const availability = venue.events.reduce((a, v) => a.concat(v.availability), []);
                            const availabilityObject = availability.find(v => v.slot.split(" ")[0] == date);

                            if (availabilityObject.city_alt != ciudad && venue.city != ciudad) {
                                return;
                            }
                        }

                        Object.keys(venue.calendar[date]).forEach(time => {
                            if (venue.calendar[date][time] < Math.max(1, shownPax)) {
                                return;
                            }

                            if (!combinedCalendar[date]) combinedCalendar[date] = {};
                            if (!combinedCalendar[date][time]) combinedCalendar[date][time] = 0;

                            combinedCalendar[date][time] += parseInt(venue.calendar[date][time]);
                        });
                    });
                });

                return <div className="modal-calendario">
                    {Helper.IsResponsive() && <div className="pax-selector">
                        <div className="pax">{shownPax} persona{shownPax != 1 && "s"}</div>
                        <div className="pax-button" onClick={e => {
                            changeFilter(ciudad, "", Math.max(1, shownPax - 1));
                        }}><Icon name="minus" size={1.5} /></div>
                        <div className="pax-button" onClick={e => {
                            changeFilter(ciudad, "", shownPax + 1);
                        }}><Icon name="plus" size={1.5} /></div>
                    </div>}
                    <Calendar
                        defaultValue={fecha}
                        months={Helper.IsResponsive() ? 1 : 2}
                        availabilityCalendar={combinedCalendar}
                        onSelectionChanged={(dates, data) => {
                            changeFilter(ciudad, dates[0], pax);
                            fechasModalRef.current.close();
                        }} />
                </div>
            }} />
    </>;
}
